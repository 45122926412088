import { Route, Routes } from "react-router-dom";

import Login from "components/auth/Login/Login";
import AuthLayout from "components/auth/AuthLayout/AuthLayout";
import ForgotPassword from "components/auth/ForgotPassword";
import ResetPassword from "components/auth/ResetPassword";
import Dashboard from "components/portal/Dashboard/Dashboard";
import Tickets from "components/portal/Tickets/Tickets";
import PrivateLayout from "components/portal/PrivateLayout";
import NotFound from "components/shared/NotFound";
import CreateTicket from "components/portal/CreateTicket";
import SelectAddress from "components/portal/CreateTicket/SelectAddress";
import TicketOverview from "components/portal/TicketOverview/TicketOverview";
import TicketDetails from "components/portal/TicketOverview/parts/TicketDetails";
import TicketStatusLog from "components/portal/TicketOverview/parts/TicketStatusLog/TicketStatusLog";
import TicketIssueLog from "components/portal/TicketOverview/parts/TicketIssueLog/TicketIssueLog";

const App = () => {
    return (
        <Routes>
            <Route path="auth" element={<AuthLayout />}>
                <Route path="login" element={<Login />} />
                <Route path="forgot-password" element={<ForgotPassword />} />
                <Route path="reset-password" element={<ResetPassword />} />
            </Route>
            <Route path="/" element={<PrivateLayout />}>
                <Route index element={<Dashboard />} />
                <Route path="tickets">
                    <Route index element={<Tickets />} />
                    <Route path="add-ticket">
                        <Route index element={<SelectAddress />} />
                        <Route path=":id/" element={<CreateTicket />} />
                    </Route>
                    <Route path=":id/" element={<TicketOverview />}>
                        <Route path="" element={<TicketDetails />} />
                        <Route path="status-logs" element={<TicketStatusLog />} />
                        <Route path="ticket-logs" element={<TicketIssueLog />} />
                    </Route>
                </Route>
            </Route>
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};

export default App;
