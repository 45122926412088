import React, { ChangeEventHandler, ComponentProps, Ref, useId } from "react";
import clsx from "clsx";

import classes from "./DbiCheckbox.module.scss";

type Props = {
    label?: string;
    labelAfter?: boolean;
    disabled?: boolean;
    validationMessage?: string[];
    inputRef?: Ref<HTMLInputElement>;
    checked: boolean;
    className?: string;
    onChange: ChangeEventHandler<HTMLInputElement>;
} & ComponentProps<"input">;

const Input = (props: Props) => {
    const { label, className, disabled, onChange, validationMessage, inputRef, checked, labelAfter = false } = props;
    const id = useId();

    return (
        <fieldset className={clsx(classes["dbi-checkbox-field"], className)}>
            {label && !labelAfter && <label className={classes["dbi-checkbox-field__label"]}>{label}</label>}
            <div className={classes["dbi-checkbox"]}>
                <input
                    ref={inputRef}
                    type="checkbox"
                    id={id}
                    className={clsx(classes["dbi-checkbox__input"], { disabled: disabled === true })}
                    onChange={onChange}
                    checked={checked}
                    placeholder={id}
                />
                <label htmlFor={id} className={classes["dbi-checkbox__label"]}></label>
            </div>
            {label && labelAfter && <label className={classes["dbi-checkbox-field__label"]}>{label}</label>}
            {validationMessage ? <div className="dbi_validation_message">{validationMessage}</div> : <></>}
        </fieldset>
    );
};

export default React.forwardRef<HTMLInputElement, Props>((props, ref) => <Input {...props} inputRef={ref} />);
