import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type State = {
    open: boolean;
    name?: string;
};

const initialState: State = {
    open: false,
};

export const ModalSlice = createSlice({
    name: "Modal",
    initialState,
    reducers: {
        openModal: (state, action: PayloadAction<boolean>) => {
            state.open = action.payload;
        },
        setCurrentModal: (state, action: PayloadAction<string>) => {
            state.name = action.payload;
            state.open = true;
        },
        closeModal: (state) => {
            state.open = false;
            state.name = undefined;
        },
    },
});

// Action creators are generated for each case reducer function
export const { closeModal, openModal, setCurrentModal } = ModalSlice.actions;
export default ModalSlice.reducer;
