import Chip from "../../Chip";
import { ITicketStatus } from "types/Ticket";
import { ComponentProps, useMemo } from "react";
import { useTranslation } from "react-i18next";

type Props = {
    status: ITicketStatus;
};

const TicketStatusChip = ({ status, ...rest }: ComponentProps<typeof Chip> & Props) => {
    const { t } = useTranslation();

    const variant: typeof rest.variant = useMemo(() => {
        const statusInt = Number(status);

        if (statusInt === ITicketStatus.Open) {
            return "warning";
        }

        if (statusInt === ITicketStatus.Approved) {
            return "success";
        }

        return undefined;
    }, [status]);

    return (
        <Chip variant={variant} {...rest}>
            {t(`tickets.status.${status}`)}
        </Chip>
    );
};

export default TicketStatusChip;
