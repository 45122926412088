import clsx from "clsx";
import { ComponentProps, PropsWithChildren } from "react";

import classes from "./Anchor.module.css";

type Type = PropsWithChildren<ComponentProps<"a">>;
const Anchor = ({ children, className, href, target = "_blank", rel = "no-referrer", ...rest }: Type) => {
    return (
        <a className={clsx(classes.a, className)} href={href} target={target} rel={rel} {...rest}>
            {children}
        </a>
    );
};
export { Anchor as default };
