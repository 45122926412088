import { AxiosResponse } from "axios";
import useClient from "api/useClient";
import { IUserViewModel } from "types/User";

type UserResource = {
    setUserLanguage: (language: string) => Promise<AxiosResponse<string>>;
    forgotPassword: (emailAddress: string) => Promise<AxiosResponse>;
    confirm: (data: ConfirmPasswordResetBody) => Promise<AxiosResponse>;
};

type EmailAddressViewModel = {
    emailAddress: string;
    callbackRoute: string;
};

type ConfirmPasswordResetBody = {
    emailConfirmationToken: string;
    password: string;
    userId: number;
};

export const useUserResource = (): UserResource => {
    const client = useClient();

    const setUserLanguage = (language: string) => {
        return client.post<string>("user/ChangeLanguage", {
            language: language,
        });
    };

    const forgotPassword = (emailAddress: string) => {
        return client.post<any, AxiosResponse, EmailAddressViewModel>("user/forgotpassword", {
            emailAddress,
            callbackRoute: `${window.origin}/auth/reset-password`,
        });
    };

    const confirm = (data: ConfirmPasswordResetBody) => {
        return client.put<IUserViewModel>("user/confirm", data);
    };

    return {
        setUserLanguage,
        forgotPassword,
        confirm,
    };
};
