import React, { useCallback, useMemo } from "react";
import clsx from "clsx";

import DbiButton from "components/shared/DbiButton";
import { useNotifications } from "hooks/useNotifications";
import { INotification } from "store/slices/NotificationsSlice";

import classes from "./NotificationsContainer.module.css";

const NotificationsContainer = () => {
    const { notifications, dismiss } = useNotifications();

    const onDismiss = useCallback(
        ({ id }: INotification) => {
            dismiss(id);
        },
        [dismiss]
    );

    const _notifications = useMemo(
        () =>
            notifications.map((notification, i) => {
                const className = clsx(classes.notification, {
                    [classes.notification__message]: notification.severity === "message",
                    [classes.notification__warning]: notification.severity === "warning",
                    [classes.notification__error]: notification.severity === "error",
                });
                return (
                    <div className={className} key={i}>
                        <span className={classes.notification__content}>{notification.content}</span>
                        {/*TODO icons8-delete*/}
                        <DbiButton label={"X"} onClick={() => onDismiss(notification)} />
                    </div>
                );
            }),
        [notifications, onDismiss]
    );
    if (_notifications.length === 0) {
        return null;
    }
    return <div className={classes.notifications_container}>{_notifications}</div>;
};

export { NotificationsContainer as default };
