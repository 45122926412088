import classes from "./Modal.module.css";

import { ExportModal } from "./ExportModal/ExportModal";
import { useAppDispatch, useAppSelector } from "../hooks";
import { closeModal } from "../store/slices/ModalSlice";

const modals = {
    exportModal: ExportModal,
};

const Modal = () => {
    const dispatch = useAppDispatch();
    const modalName = useAppSelector((state) => state.modals.name);
    const modalOpen = useAppSelector((state) => state.modals.open);

    const currentModal = modals[modalName as keyof typeof modals];
    const Component = currentModal;

    const handleModalClose = () => {
        dispatch(closeModal());
    };

    return modalOpen ? (
        <div className={classes.modal}>
            <div className={classes.wrapper}>
                <Component onClose={handleModalClose} />
            </div>
        </div>
    ) : null;
};

export default Modal;
