import i18next from "i18next";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import enImg from "../../../assets/images/en.svg";
import nlImg from "../../../assets/images/nl.svg";
import deImg from "../../../assets/images/de.svg";

import classes from "./LanguageSwitch.module.css";
import OutsideClick from "helpers/outside";
import { UserService } from "services";

const languages = [
    { value: "nl", image: nlImg },
    { value: "en", image: enImg },
    { value: "de", image: deImg },
];

const LanguageSwitch = () => {
    const { t } = useTranslation();
    const userService = UserService();
    const [showOptions, setShowOption] = useState(false);

    const currentLanguage = i18next.language;

    const setLanguage = async (lang: string) => {
        const newLanguage: string = await userService.setLanguage(lang);
        i18next.changeLanguage(newLanguage);
        setShowOption(false);
    };

    const renderCurrentFlag = (lang: string) => {
        // NOTE: Null check added for users with default by-backend provided language being 'nl-NL'.
        const current = languages.find((item) => item.value === lang) ?? languages[0];

        const flag = current?.image;
        return <img className={classes.flag} src={flag} alt="lang" />;
    };

    const closeMenu = () => {
        setShowOption(false);
    };

    return (
        <OutsideClick onOutsideClick={() => closeMenu()}>
            <div className={classes.switch}>
                <div className={classes.current} onClick={() => setShowOption(!showOptions)}>
                    {renderCurrentFlag(currentLanguage)}
                    {t(`language.${currentLanguage}`, "NL")}
                </div>
                {showOptions && (
                    <ul className={classes.dropdown}>
                        {languages.map((lang, idx: number) => (
                            <li key={idx} data-value={lang.value} onClick={() => setLanguage(lang.value)}>
                                <img className={classes.flag} src={lang.image} alt={lang.value} />
                                {t(`language.${lang.value}`)}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </OutsideClick>
    );
};

export default LanguageSwitch;
