import { ReactNode } from "react";
import { Link } from "react-router-dom";

import UserInfo from "../UserInfo/UserInfo";

// classes
import classes from "./TopBar.module.css";

// assets
import { DbiLogo } from "../DbiLogo/DbiLogo";

type Props = {
    buttons?: ReactNode;
    breadcrumb?: string;
};

const TopBar = (props: Props) => {
    const { buttons, breadcrumb } = props;

    return (
        <div className={classes.topbar}>
            <div className={classes.logo}>
                <Link to={"/"}>
                    <DbiLogo themeColor="dark" />
                </Link>
            </div>
            <div className={classes.content}>
                <div className={classes.breadcrumb}>
                    <span>{breadcrumb}</span>
                </div>
                {buttons && <div className={classes.buttons}>{buttons}</div>}
            </div>
            <div className={classes.user}>
                <UserInfo />
            </div>
        </div>
    );
};

export default TopBar;
