import clsx from "clsx";
import { ComponentProps } from "react";
import { useTranslation } from "react-i18next";
import Svg from "./Svg";

type ButtonVariant =
    | "primary"
    | "secondary"
    | "ghost"
    | "red"
    | "red-outline"
    | "primary-outline"
    | "success"
    | "warning";

type Props = Omit<ComponentProps<"button">, "className"> & {
    // TODO Allow for specific icon content
    label: string;
    variant?: ButtonVariant;
    loading?: boolean;
    className?: string;
};

const DbiButton = (props: Props) => {
    const { label, variant, loading, className, ...rest } = props;
    const { t } = useTranslation();

    return (
        <button className={clsx("button", className, { [`button--${variant}`]: variant })} {...rest}>
            {loading ? <Svg text={t("loading")} /> : label}
        </button>
    );
};

export default DbiButton;
