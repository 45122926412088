import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { formatDate, getCardStatusColor } from "helpers";

import Card from "components/shared/Card/index";

import classes from "./CardCard.module.css";
import { ICard } from "types";
import { useEffect, useState } from "react";

type Props = {
    className?: string;
    card: ICard;
};

const CardCard = (props: Props) => {
    const { className, card } = props;
    const { t } = useTranslation();
    const [statusColor, setStatusColor] = useState<string>();

    useEffect(() => {
        const color = getCardStatusColor(card.status);
        setStatusColor(color);
    }, [card.status]);

    return (
        <Card className={clsx(className)}>
            <div className={classes.content}>
                <div className={clsx(classes.bin_name, classes.flexRow)}>
                    <div>Pasnummer: {card.internalCardNumber}</div>
                    <span style={{ color: statusColor }}>{t("card.status." + card.status)}</span>
                </div>

                <div className={classes.bin_timestamp}>
                    {t("ticketDetail.card.externalCardNumber", {
                        card: card.externalCardNumber,
                    })}
                </div>
                <div className={classes.bin_timestamp}>
                    {t("ticketDetail.card.since", {
                        date: formatDate(card.connectedOn),
                    })}
                </div>
            </div>
        </Card>
    );
};

export { CardCard as default };
