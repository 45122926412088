import clsx from "clsx";
import { useTranslation } from "react-i18next";
import classes from "../InfoBlocks.module.scss";

type Props = {
    customContent?: string;
};

export const TicketBlock = (props: Props) => {
    const { customContent } = props;
    const { t } = useTranslation();

    return (
        <div className={classes.block}>
            <i className={clsx("icons8-energy-meter-filled", classes.icon_big)} />
            <i className={clsx("icons8-energy-meter-filled", classes.icon_small)} />
            <div className={classes.title}>
                <div className="success"></div>
                {customContent}
            </div>
            <div className={classes.content}>{t("tickets.openTickets.completed")}</div>
        </div>
    );
};
