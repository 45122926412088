import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { IResources } from "types";
import { FALLBACK_LNG } from "const/locale";

import enLang from "./en.json";
import nlLang from "./nl.json";
import deLang from "./de.json";

import "moment/locale/nl";

export const resources: IResources = {
    en: { translation: enLang, topLevelDomain: ".co.uk" },
    nl: { translation: nlLang, topLevelDomain: ".nl" },
    de: { translation: deLang, topLevelDomain: ".de" },
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        debug: process.env.NODE_ENV === "development",
        fallbackLng: FALLBACK_LNG,
        returnObjects: true,
        interpolation: {
            escapeValue: false,
        },
    })
    .then();

export default i18n;
