import axios, { AxiosError } from "axios";
import qs from "qs";

import config from "./config";

const endpoint = config.apiUrl;

const client = (token: string | undefined, onUnauthorized: () => void) => {
    const instance = axios.create({
        baseURL: endpoint,
        timeout: 20000,
        paramsSerializer(params) {
            return qs.stringify(params, {
                arrayFormat: "repeat",
            });
        },
        headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
        },
    });
    instance.interceptors.response.use(
        (response) => response,
        (error: AxiosError) => {
            if (error.response?.status === 401) {
                onUnauthorized();
            }
            return Promise.reject(error);
        }
    );
    return instance;
};

export default client;
