import { useEffect, useState } from "react";
import { formatDateTime } from "helpers/date";
import { useTranslation } from "react-i18next";
import { ITicketStatus } from "types/Ticket";

import TicketRemarks from "../TicketRemarks";
import DbiInput from "components/shared/DbiInput";
import { useTicketData } from "../../useTicketData";
import DbiTextArea from "components/shared/DbiTextArea";
import { ContainerBox } from "components/shared/ContainerBox";

import classes from "./TicketDetails.module.scss";

type ClosedStatus = {
    closedOn: string;
    closedBy: string;
};

const TicketDetails = () => {
    const { t } = useTranslation();
    const {
        ticketStateData: ticketData,
        containerStateData: containerData,
        cardsStateData: cardData,
    } = useTicketData();

    const [closedStatus, setClosedStatus] = useState({} as ClosedStatus);

    useEffect(() => {
        if (ticketData?.statusLog) {
            const lastClosedStatus = ticketData.statusLog[ticketData.statusLog.length - 1];
            setClosedStatus({
                closedOn: lastClosedStatus.occurredOn,
                closedBy: lastClosedStatus.user.name ?? "",
            });
        }
    }, [ticketData]);

    return (
        <div className={classes.block}>
            <div className={classes.flexRow}>
                <div className={classes.halfScreen}>
                    <div className={classes.dbi_input_half}>
                        <DbiInput
                            type="text"
                            label={t("ticket.table.createdOn")}
                            value={ticketData?.createdOn ? formatDateTime(ticketData.createdOn) : "-"}
                            disabled
                            readOnly
                        />
                    </div>
                    {ticketData?.status === ITicketStatus.Approved && (
                        <div className={classes.dbi_input_half}>
                            <DbiInput
                                type="text"
                                label={t("ticket.table.closedOn")}
                                disabled
                                value={closedStatus.closedOn ? formatDateTime(closedStatus.closedOn) : "-"}
                                readOnly
                            />
                        </div>
                    )}

                    <div className={classes.dbi_input_half}>
                        <DbiInput
                            type="text"
                            label={t("ticket.table.createdBy")}
                            value={ticketData?.createdBy ?? "-"}
                            disabled
                            readOnly
                        />
                    </div>
                    {ticketData?.status === ITicketStatus.Approved && (
                        <div className={classes.dbi_input_half}>
                            <DbiInput
                                type="text"
                                label={t("ticket.table.closedBy")}
                                disabled
                                value={closedStatus.closedBy ?? "-"}
                                readOnly
                            />
                        </div>
                    )}

                    <h5 className={classes.customerTitle}>{t("ticketDetail.subjectAndActions")}</h5>

                    <div className={classes.dbi_input_half}>
                        <DbiInput
                            type="text"
                            label="onderwerp"
                            value={ticketData?.subject?.title ?? "-"}
                            disabled
                            readOnly
                        />
                    </div>

                    <div className={classes.dbi_input_half}>
                        <DbiInput
                            type="text"
                            label={t("ticket.table.actions")}
                            value={ticketData?.subject?.items.map((i) => i.title).join(", ") ?? ""}
                            disabled
                            readOnly
                        />
                    </div>

                    <div className={classes.dbi_input_half}>
                        <DbiTextArea
                            disabled
                            rows={8}
                            readOnly
                            label={t("ticket.table.actionRemark")}
                            value={ticketData?.actionRemark}
                        />
                    </div>

                    {ticketData?.card && (
                        <div className={classes.dbi_input_half}>
                            <DbiInput
                                label={t("ticket.table.card")}
                                value={ticketData.card?.internalCardNumber}
                                disabled
                                readOnly
                            />
                        </div>
                    )}
                    {ticketData?.undergroundContainer && (
                        <div className={classes.dbi_input_half}>
                            <DbiInput
                                label={t("ticket.table.ucContainer")}
                                value={ticketData.undergroundContainer.name}
                                disabled
                                readOnly
                            />
                        </div>
                    )}
                    {ticketData?.wheelieBin && (
                        <div className={classes.dbi_input_half}>
                            <DbiInput
                                label={t("ticket.table.container")}
                                value={ticketData.wheelieBin?.name}
                                disabled
                                readOnly
                            />
                        </div>
                    )}

                    {ticketData?.inquiry && (
                        <div className={classes.dbi_input}>
                            <DbiInput
                                label={t("ticket.table.inquiry")}
                                value={ticketData.inquiry?.content}
                                disabled
                                type="text"
                                readOnly
                                className={classes.dbi_input}
                            />
                        </div>
                    )}

                    {ticketData?.description && (
                        <div className={classes.dbi_input}>
                            <DbiTextArea
                                disabled
                                rows={8}
                                readOnly
                                label={t("ticket.table.description")}
                                value={ticketData.description ? ticketData.description : "-"}
                            />
                        </div>
                    )}

                    <h5 className={classes.customerTitle}>{t("ticketDetail.customerContactDetails")}</h5>

                    <div className={classes.dbi_input_half}>
                        <DbiInput
                            readOnly
                            disabled
                            value={ticketData?.contactEmailAddress ?? ""}
                            label={t("ticketDetail.emailAddress")}
                        />
                    </div>
                    <div className={classes.dbi_input_half}>
                        <DbiInput
                            readOnly
                            disabled
                            value={ticketData?.contactPhoneNumber ?? ""}
                            label={t("ticketDetail.phoneNumber")}
                        />
                    </div>

                    {ticketData && <TicketRemarks ticketId={ticketData.id} remarks={ticketData.remarks} />}
                </div>

                <ContainerBox
                    address={ticketData?.address}
                    containers={containerData}
                    cards={cardData}
                    activeWheelieBin={ticketData?.wheelieBin ? ticketData.wheelieBin.wheelieBinId : 0}
                />
            </div>
        </div>
    );
};

export default TicketDetails;
