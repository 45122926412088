import { IAddressViewModel } from "./Address";
import { DateString } from "./index";

export interface ICardOption {
    identifier: number;
    label: string;
}

export interface ICard {
    cardId: number;
    externalCardNumber: string;
    internalCardNumber: string;
    cardSystemType: CardSystemType;
    cardType: CardType;
    address: IAddressViewModel;
    addressId: number;
    status: CardStatus;
    blacklist: boolean;
    balance: number;
    bringLocationCount: number;
    transactionCount: number;
    ucTransactionId?: number;
    expirationDate?: DateString;
    connectedOn: DateString;
}

export enum CardType {
    Postpaid = 0,
    Prepaid = 1,
    Service = 2,
    Empty = 3,
}

export enum CardStatus {
    Active = 0,
    Blocked = 1,
}

export enum CardSystemType {
    Mifare = 0,
    Tiris = 1,
}
