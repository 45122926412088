import classes from "./FullPageError.module.css";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import DbiButton from "components/shared/DbiButton";

type Props = {
    header?: string;
    statusCode?: string;
    navigateTo?: string;
    onReturn?: () => void;
};

export default function FullPageError({ statusCode, header, navigateTo, onReturn }: Props) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const to = navigateTo ?? "/";
    header = header ?? t("errors.generic");
    statusCode = statusCode ?? "404";

    return (
        <div className={classes.root}>
            <div className={clsx(classes.flex, classes.column, classes.content)}>
                <h3>{header}</h3>
                <DbiButton
                    label={t("errors.returnToSafety")}
                    variant={"primary"}
                    onClick={() => {
                        navigate(to);
                        onReturn?.();
                    }}
                />
            </div>
            <div className={clsx(classes.flex, classes.bg_text_container)}>
                <div>{statusCode}</div>
            </div>
        </div>
    );
}
