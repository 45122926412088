import { useAppDispatch, useAppSelector } from "hooks/useStore";
import { useAccountResource } from "api/resources";
import { logout, set2FAToken, setAccessToken } from "store/slices/AuthSlice";
import { AxiosError } from "axios";
import i18next from "i18next";

const AuthService = () => {
    const dispatch = useAppDispatch();
    const resource = useAccountResource();

    // Selector (global state)
    const { rememberDays, accessToken, rememberTwoFactorToken } = useAppSelector((state) => state.auth);

    const signIn = async (email: string, password: string) => {
        return await resource
            .generateAccessToken({
                username: email,
                password,
                rememberDays,
                rememberTwoFactorToken,
            })
            .then((resp) => {
                if (resp.status === 200) {
                    const { accessToken, language, requiresTwoFactor, rememberTwoFactorToken, rememberDays } =
                        resp.data;

                    i18next.changeLanguage(language);
                    if (requiresTwoFactor) {
                        dispatch(set2FAToken({ accessToken, rememberDays }));
                        return {
                            requiresTwoFactor: true,
                        };
                    }

                    dispatch(setAccessToken({ accessToken, rememberTwoFactorToken: resp.data.rememberTwoFactorToken }));
                    return {
                        success: {
                            message: "User logged in",
                            rememberTwoFactorToken,
                        },
                    };
                } else {
                    return {
                        error: {
                            message: "Login failed",
                        },
                    };
                }
            })
            .catch((err) => {
                const error = err as AxiosError;
                const responseData = error.response?.data.data ?? error.message;

                return {
                    error: {
                        message: responseData,
                    },
                };
            });
    };

    const signOut = () => {
        dispatch(logout);
    };

    const generateTwoFactorAccessToken = async (code: string, remember: boolean) => {
        return await resource
            .generateTwoFactorAccessToken(accessToken, { code, remember })
            .then((resp) => {
                if (resp.status === 409) {
                    return { error: { message: "Invalid 2FA token" } };
                } else {
                    const { accessToken, language, rememberTwoFactorToken } = resp.data;
                    dispatch(setAccessToken({ accessToken, rememberTwoFactorToken, remember }));
                    i18next.changeLanguage(language);
                    return { success: { message: "User signed in with 2FA code" } };
                }
            })
            .catch((err) => {
                const error = err as AxiosError;
                const responseData = error.response?.data.data ?? error.message;

                return {
                    error: {
                        message: responseData,
                    },
                };
            });
    };

    const generateTwoFactorAccessTokenUsingRecoveryToken = async (code: string) => {
        return await resource
            .generateTwoFactorAccessTokenUsingRecoveryToken(accessToken, { code, remember: false })
            .then((resp) => {
                if (resp.status === 409) {
                    return { error: { message: "Invalid restore token" } };
                } else {
                    const { accessToken, language } = resp.data;
                    dispatch(setAccessToken({ accessToken }));
                    i18next.changeLanguage(language);
                    return { success: { message: "User signed in with restore token" } };
                }
            })
            .catch((err) => {
                const error = err as AxiosError;
                const responseData = error.response?.data.data ?? error.message;

                return {
                    error: {
                        message: responseData,
                    },
                };
            });
    };

    return {
        signIn,
        signOut,
        generateTwoFactorAccessToken,
        generateTwoFactorAccessTokenUsingRecoveryToken,
    };
};

export default AuthService;
