import classes from "./Link.module.css";
import { ComponentProps, PropsWithChildren } from "react";

type Props = {
    label?: string;
} & PropsWithChildren &
    ComponentProps<"button">;

export const Link = (props: Props) => {
    const { label, children } = props;
    return (
        <button {...props} className={classes.link}>
            {label ?? children}
        </button>
    );
};
