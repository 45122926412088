import { useUserResource } from "api/resources";

const UserService = () => {
    const { setUserLanguage } = useUserResource();

    const setLanguage = async (language: string) => {
        return await setUserLanguage(language).then((resp) => {
            if (resp.status === 200) {
                return language;
            } else {
                return "error";
            }
        });
    };

    return {
        setLanguage,
    };
};

export default UserService;
