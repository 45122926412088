import classes from "./DbiLogo.module.scss";

type Props = {
    themeColor: "dark" | "light";
};

export const DbiLogo: React.FC<Props> = ({ themeColor }) => {
    return (
        <svg id="Text" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 217 130">
            <defs>
            </defs>
            <g id="Text_white">
                <path className={classes[themeColor]} d="M.48,39.46c2.34-.49,6.03-.86,9.79-.86,5.35,0,8.8.92,11.39,3.02,2.15,1.6,3.45,4.06,3.45,7.32,0,4-2.65,7.51-7.02,9.11v.12c3.94.98,8.56,4.25,8.56,10.4,0,3.57-1.42,6.28-3.51,8.31-2.89,2.65-7.57,3.88-14.34,3.88-3.69,0-6.52-.25-8.31-.49v-40.81ZM5.83,56.44h4.86c5.66,0,8.99-2.95,8.99-6.96,0-4.86-3.69-6.77-9.11-6.77-2.46,0-3.88.18-4.74.37v13.36ZM5.83,76.33c1.05.18,2.59.25,4.49.25,5.54,0,10.65-2.03,10.65-8.06,0-5.66-4.86-8-10.71-8h-4.43v15.82Z"/>
                <path className={classes[themeColor]} d="M33.65,80.39v-29.79h5.42v29.79h-5.42Z"/>
                <path className={classes[themeColor]} d="M48.06,58.66c0-3.08-.06-5.6-.25-8.06h4.8l.31,4.92h.12c1.48-2.83,4.92-5.6,9.85-5.6,4.12,0,10.53,2.46,10.53,12.68v17.79h-5.42v-17.17c0-4.8-1.79-8.8-6.89-8.8-3.57,0-6.34,2.52-7.26,5.54-.25.68-.37,1.6-.37,2.52v17.91h-5.42v-21.73Z"/>
                <path className={classes[themeColor]} d="M82.77,80.39v-25.67h-4.19v-4.12h4.19v-1.6c0-7.88,4.62-12.99,12.99-12.99,2.77,0,6.03.86,7.57,1.97l-1.54,4.12c-1.29-.92-3.63-1.72-6.28-1.72-5.72,0-7.39,4-7.39,8.74v1.48h17.3v29.79h-5.35v-25.67h-11.94v25.67h-5.36Z"/>
                <path className={classes[themeColor]} d="M114.41,58.66c0-3.08-.06-5.6-.25-8.06h4.8l.31,4.92h.12c1.48-2.83,4.92-5.6,9.85-5.6,4.12,0,10.53,2.46,10.53,12.68v17.79h-5.42v-17.17c0-4.8-1.78-8.8-6.89-8.8-3.57,0-6.34,2.52-7.26,5.54-.25.68-.37,1.6-.37,2.52v17.91h-5.42v-21.73Z"/>
                <path className={classes[themeColor]} d="M148.57,80.39v-29.79h5.42v29.79h-5.42Z"/>
                <path className={classes[themeColor]} d="M169.5,42.04v8.56h7.76v4.12h-7.76v16.07c0,3.69,1.05,5.79,4.06,5.79,1.42,0,2.46-.18,3.14-.37l.25,4.06c-1.05.43-2.71.74-4.8.74-2.52,0-4.55-.8-5.85-2.28-1.54-1.6-2.09-4.25-2.09-7.76v-16.25h-4.62v-4.12h4.62v-7.14l5.29-1.42Z"/>
                <path className={classes[themeColor]} d="M185.87,50.6l6.53,17.6c.68,1.97,1.41,4.31,1.91,6.09h.12c.55-1.79,1.17-4.06,1.91-6.22l5.91-17.48h5.72l-8.12,21.24c-3.88,10.22-6.52,15.45-10.22,18.65-2.65,2.34-5.29,3.26-6.65,3.51l-1.35-4.55c1.35-.43,3.14-1.29,4.74-2.65,1.48-1.17,3.32-3.26,4.55-6.03.25-.55.43-.98.43-1.29s-.12-.74-.37-1.42l-11.02-27.45h5.91Z"/>
                <circle className="cls-1" cx="210.66" cy="75.34" r="4.23"/>
            </g>
        </svg>
    );
};
