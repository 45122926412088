import { Children, cloneElement, PropsWithChildren } from "react";
import clsx from "clsx";

import classes from "./BoxLinker.module.scss";

type Props = {
    title: string;
    connected?: boolean;
};

const BoxLinker = ({ title, connected, children }: PropsWithChildren<Props>) => {
    return (
        <div className={clsx(classes.container, { [classes.connector]: connected })}>
            <h6 className={classes.title}>{title}</h6>
            {Children.map(children, (child: any) =>
                cloneElement(child, {
                    className: clsx(classes.child, child.props.className),
                })
            )}
        </div>
    );
};
export { BoxLinker as default };
