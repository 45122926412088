import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction } from "react";
import MaterialReactTable, { MRT_PaginationState } from "material-react-table";

type Props = {
    columns: any[];
    data: any[];
    dataCount?: number;
    onRowClick?: (id: number) => void;
    options?: {
        enablePagination?: boolean;
    };
    setPagination?: Dispatch<SetStateAction<MRT_PaginationState>>;
    pagination?: MRT_PaginationState;
    contentName?: string;
    idKey?: string;
};

export const DbiTable = (props: Props) => {
    const { t } = useTranslation();

    const {
        columns,
        data,
        onRowClick,
        options,
        setPagination,
        dataCount,
        pagination,
        idKey,
        contentName = "items",
    } = props;

    const state = pagination ? { pagination } : undefined;
    const key = idKey || "id";
    const contentNameLowerCase = contentName.toLocaleLowerCase();

    return (
        <MaterialReactTable
            columns={columns}
            data={data}
            rowCount={dataCount}
            manualFiltering
            manualPagination
            enableColumnActions={false}
            enableColumnFilters={false}
            onPaginationChange={setPagination}
            enablePagination={options?.enablePagination || false}
            enableSorting={false}
            enableBottomToolbar={options?.enablePagination || false}
            enableTopToolbar={false}
            enableRowSelection={false}
            muiTableBodyRowProps={({ row }) => ({
                onClick: onRowClick ? () => onRowClick(row.original[key]) : undefined,
                hover: onRowClick ? true : false,
                sx: onRowClick ? { cursor: "pointer" } : undefined,
            })}
            muiTablePaperProps={{
                elevation: 0,
                sx: {
                    borderRadius: "0",
                    border: 0,
                },
            }}
            muiTableHeadCellProps={{
                sx: {
                    background: "rgb(242,244,245)",
                    fontSize: "14.4px",
                    color: "rgb(17, 60, 98)",
                    textTransform: "uppercase",
                },
            }}
            state={state}
            localization={{
                of: t("table.of"),
                rowsPerPage: t("table.rowPerPage", { contentName }),
                noRecordsToDisplay: t("table.noRecordsToDisplay", { contentNameLowerCase }),
                noResultsFound: t("table.noResultsFound", { contentNameLowerCase }),
            }}
        />
    );
};
