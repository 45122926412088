import { useNavigate, useSearchParams } from "react-router-dom";
import { FormEvent, useCallback, useEffect, useRef, useState } from "react";
import { useEffectOnce } from "usehooks-ts";
import { useTranslation } from "react-i18next";

import { isValidationMessages, IValidationMessages } from "types/ValidationMessages";
import DbiInput from "components/shared/DbiInput";
import DbiButton from "components/shared/DbiButton";
import { useUserResource } from "api/resources";
import { isServerError } from "types";

import classes from "./ResetPassword.module.css";

const ResetPassword = () => {
    const [searchParams] = useSearchParams();
    const { t } = useTranslation();
    const nav = useNavigate();

    const p1ref = useRef<HTMLInputElement>(null);
    const [loading, setLoading] = useState(false);

    const [success, setSuccess] = useState(false);
    const [p1, setP1] = useState("");
    const [p2, setP2] = useState("");
    const [error, setError] = useState("");
    const [validationMessages, setValidationMessages] = useState<IValidationMessages>({});

    const { confirm } = useUserResource();

    useEffectOnce(() => {
        if (!searchParams.has("id") || !searchParams.has("token")) {
            nav("/auth/login");
            return;
        }
    });

    // Return to root after x ms when changing password was successful
    useEffect(() => {
        if (!success) return;
        setTimeout(() => {
            nav("/", { replace: true });
        }, 1000);
    }, [nav, success]);

    const handleSubmit = useCallback(
        (e: FormEvent) => {
            e.preventDefault();
            if (p1 !== p2) {
                setError(t("auth.resetPassword.no-match", "De wachtwoorden komen niet overeen"));
                p1ref.current?.select();
                return;
            }
            setLoading(true);

            const emailConfirmationToken = searchParams.get("token")!;
            const userId = Number(searchParams.get("id")!);
            confirm({
                password: p1,
                emailConfirmationToken,
                userId,
            })
                .then(() => {
                    setSuccess(true);
                })
                .catch((reason) => {
                    const data = reason.response.data;
                    if (isServerError(data)) setError(data.data);
                    else if (isValidationMessages(data)) setValidationMessages(data);
                })
                .finally(() => setLoading(false));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [p1, p2, searchParams]
    );

    return (
        <div className="reset-password">
            {success ? (
                <>
                    <h3>{t("auth.resetPassword.success")}</h3>
                    <p>{t("auth.resetPassword.success.content")}</p>
                </>
            ) : (
                <>
                    <h3>{t("auth.resetPassword", "Wachtwoord resetten")}</h3>
                    <p>{t("auth.resetPassword.content", "Voer een nieuw wachtwoord in")}</p>
                    <form onSubmit={handleSubmit}>
                        <DbiInput
                            className={classes.input}
                            label={t("auth.resetPassword.newPassword")}
                            placeholder={t("auth.resetPassword.newPassword.placeholder", "Uw nieuwe wachtwoord")}
                            required
                            type={"password"}
                            autoComplete={"new-password"}
                            validationMessage={validationMessages["password"]}
                            ref={p1ref}
                            value={p1}
                            onChange={(e) => setP1(e.target.value)}
                        />
                        <DbiInput
                            className={classes.input}
                            label={t("auth.resetPassword.confirmNewPassword", "Wachtwoord bevestigen")}
                            placeholder={t("auth.resetPassword.newPassword.placeholder", "Uw nieuwe wachtwoord")}
                            required
                            type={"password"}
                            autoComplete={"new-password"}
                            value={p2}
                            onChange={(e) => setP2(e.target.value)}
                        />
                        <DbiButton label={t("auth.resetPassword")} type={"submit"} loading={loading} />
                    </form>
                    {error && (
                        <div className={classes.errors}>
                            <span>{error}</span>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};
export default ResetPassword;
