import Cookies from "js-cookie";
import { useCallback } from "react";
import client from "./client";
import { useLocation, useNavigate } from "react-router-dom";

const useClient = () => {
    const nav = useNavigate();
    const { pathname } = useLocation();

    const onUnauthorized = useCallback(() => {
        nav(`/auth/login?redirectTo=${pathname}`);
    }, [nav, pathname]);

    return client(Cookies.get("jwt-dbi"), onUnauthorized);
};

export default useClient;
