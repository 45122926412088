import { Trans, useTranslation } from "react-i18next";
import { useCallback, useState } from "react";

import Modal from "components/shared/Modal";
import Input from "components/shared/DbiInput";
import { Link } from "components/shared/Link";
import DbiCheckbox from "components/shared/DbiCheckbox";
import { useAppSelector } from "hooks";

import classes from "./TwoFactorModal.module.scss";

type Props = {
    onClose: Function;
    onSubmit2faCode: (code: string, remember: boolean, isRecovery: boolean) => void;
    show: boolean;
    validationMessages: string[];
};

export const TwoFactorModal = ({ onClose, onSubmit2faCode, show, validationMessages }: Props) => {
    const { t } = useTranslation();
    const { canRemember, rememberDays } = useAppSelector((state) => state.auth);
    const [remember, setRemember] = useState(false);

    // State
    const [code, setCode] = useState("");
    const [recoveryMode, setRecoveryMode] = useState(false);

    const switchMode = useCallback(() => {
        setRecoveryMode((s) => !s);
    }, []);

    const handleOnSubmit = useCallback(() => {
        onSubmit2faCode(code, remember, recoveryMode);
    }, [code, onSubmit2faCode, recoveryMode, remember]);

    const body = !recoveryMode ? (
        <>
            <div className={classes.row}>
                <Input
                    autoComplete="off"
                    autoFocus
                    inputMode="numeric"
                    label={t("twoFactor.about.verificationCode")}
                    maxLength={6}
                    minLength={6}
                    name="token"
                    onChange={(event) => setCode(event.target.value)}
                    required={true}
                    validationMessage={validationMessages}
                    value={code}
                />
            </div>
            {canRemember && (
                <div className={classes.row}>
                    <DbiCheckbox
                        label={t("twoFactor.remember", { rememberDays })}
                        checked={remember}
                        onChange={(event) => setRemember(event.target.checked)}
                    />
                </div>
            )}
            <div className={classes.row}>
                <Trans
                    i18nKey={"twoFactor.about.switch.recovery"}
                    components={{ btn: <Link onClick={switchMode} /> }}
                    default={"Authenticator kwijt? <btn>Klik hier</btn> om uw herstelcode in te vullen."}
                />
            </div>
        </>
    ) : (
        <>
            <div className={classes.row}>
                <Input
                    label={t("twoFactor.recovery.code.singular")}
                    value={code}
                    required={true}
                    onChange={(event) => setCode(event.target.value)}
                />
            </div>
            <div className={classes.row}>
                <Trans
                    i18nKey={"twoFactor.about.switch.twoFactor"}
                    components={{ btn: <Link onClick={switchMode} /> }}
                    default={"<btn>Klik hier</btn> om uw authenticator te gebruiken"}
                />
            </div>
        </>
    );

    return (
        <Modal
            cancelText={t("ticketDetail.newRemarkModal.cancel")}
            confirmText={t("auth.send")}
            onClose={onClose}
            onSave={handleOnSubmit}
            show={show}
            title={t("twoFactor.title")}
        >
            <div>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleOnSubmit();
                    }}
                >
                    {body}
                </form>
            </div>
        </Modal>
    );
};
