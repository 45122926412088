export type Option = {
    value: number;
    label: string;
};

export function mapDataToSelectOptions<T>(data: T[], valueKey: keyof T, textKey: keyof T) {
    if (!data) return;
    const newData: Option[] = data.map((item) => ({
        value: Number(item[valueKey]),
        label: String(item[textKey]),
    }));
    return newData;
}
