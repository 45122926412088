import { FormEvent, useCallback, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";

import DbiInput from "components/shared/DbiInput";
import DbiButton from "components/shared/DbiButton";
import { useUserResource } from "api/resources";
import { isValidationMessages, IValidationMessages } from "types/ValidationMessages";
import { isServerError, ServerError } from "types";

import classes from "./ForgotPassword.module.css";

const ForgotPassword = () => {
    const { t } = useTranslation();
    const nav = useNavigate();

    const [params] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [email, setEmail] = useState<string>(params.get("email") ?? "");
    const [validationMessages, setValidationMessages] = useState<IValidationMessages>({});

    const { forgotPassword } = useUserResource();

    const handleSubmit = useCallback(
        (e: FormEvent) => {
            e.preventDefault();

            setLoading(true);
            forgotPassword(email)
                .then(() => {
                    setSuccess(true);
                })
                .catch((reason: AxiosError<ServerError>) => {
                    const data = reason.response?.data;
                    if (isServerError(data)) {
                        setValidationMessages({ emailAddress: [data?.data] });
                        return;
                    }
                    if (isValidationMessages(data)) {
                        setValidationMessages(data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [email, forgotPassword]
    );

    return (
        <div className="forgot-password">
            {success ? (
                <>
                    <h1>{t("auth.forgotPassword.success")}</h1>
                    <p>{t("auth.forgotPassword.success.content", { email })}</p>
                    <DbiButton
                        className={classes["buttons__return-to-login"]}
                        label={t("auth.backToLogin")}
                        onClick={() => nav(-1)}
                    />
                </>
            ) : (
                <>
                    <h1>{t("auth.forgotPassword")}</h1>
                    <p>{t("auth.forgotPasswordText")}</p>
                    <form onSubmit={handleSubmit}>
                        <DbiInput
                            autoFocus
                            className={classes.input}
                            label={t("auth.email")}
                            placeholder={t("auth.emailPlaceholder")}
                            autoComplete="email"
                            required
                            validationMessage={validationMessages["emailAddress"]}
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                        />
                        <div className={classes.buttons}>
                            <Link className="button button--ghost" to="/auth/login">
                                {t("auth.backToLogin")}
                            </Link>
                            <DbiButton
                                variant={"primary"}
                                loading={loading}
                                label={t("auth.forgotPassword.request", "Aanvragen")}
                                type={"submit"}
                            />
                        </div>
                    </form>
                </>
            )}
        </div>
    );
};
export default ForgotPassword;
