import { useEffect, useRef } from "react";

type Props = {
    onOutsideClick: () => void;
    children: React.ReactNode;
};

const useAlerter = (ref: any, onOutsideClick: () => void) => {
    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                onOutsideClick();
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, onOutsideClick]);
};

const OutsideClick = (props: Props) => {
    const { children, onOutsideClick } = props;
    const wrapperRef = useRef(null);

    useAlerter(wrapperRef, onOutsideClick);

    return <div ref={wrapperRef}>{children}</div>;
};

export default OutsideClick;
