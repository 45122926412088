import { useOutletContext } from "react-router-dom";
import { ITicketDetail, IWheelieBin, ICard } from "types";

type TicketContext = {
    ticketStateData: ITicketDetail;
    containerStateData: IWheelieBin[];
    cardsStateData: ICard[];
};

export function useTicketData() {
    return useOutletContext<TicketContext>();
}
