export const GET_ALL_TICKETS = "tickets";
export const GET_TICKET = "ticket";

export const GET_CONTAINERS = "containers";

export const GET_ADDRESSES = "addresses";
export const GET_ADDRESS = "adres";
export const GET_TICKETS_BY_ADDRESS = "ticketsByAddres";

export const GET_CARDS = "cards";

export const GET_BRING_LOCATIONS = "bringLocations";
