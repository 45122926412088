import { Navigate } from "react-router-dom";

/**
 * Until dashboard has a specific implementation, this page redirects to the ticket overview
 */
const Dashboard = () => {
    return <Navigate to={"/tickets"} />;
    /*const { t } = useTranslation();
    const title = t("dashboard.title");
    return (
        <BaseLayout pageTitle={title}>
            <h1>{title}</h1>
        </BaseLayout>
    );*/
};

export default Dashboard;
