import { ComponentProps } from "react";
import clsx from "clsx";

type Props = {
    label?: string;
    direction?: "row" | "column";
    disabled?: boolean;
    textarea?: boolean;
    readOnly?: boolean;
    validationMessage?: string[];
    rows?: number;
} & ComponentProps<"textarea">;

const Input: React.FC<Props> = (props) => {
    const { label, direction, className, disabled, textarea, rows = 6, validationMessage, ...rest } = props;

    return (
        <fieldset className={clsx("dbi-input-field", { "dbi-input-field--column": direction === "column" })}>
            {label && <label className="dbi-input-field__label">{label}</label>}
            <div className="dbi-input-field__content">
                <textarea
                    rows={rows}
                    className={clsx("dbi-input-field__input", "dbi-input-field-textarea", {
                        disabled: disabled === true,
                    })}
                    {...rest}
                />
                {validationMessage ? <div className="dbi_validation_message">{validationMessage}</div> : <></>}
            </div>
        </fieldset>
    );
};

export default Input;
