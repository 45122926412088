import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { Link, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import type { MRT_ColumnDef, MRT_PaginationState } from "material-react-table";

import { useTicketResource } from "api/resources";
import { useAppDispatch, useQueryParams } from "hooks";
import { setCurrentModal } from "store/slices/ModalSlice";
import { ITicket, ITicketStatus, PAGE_SIZES } from "types";
import { formatDateTime, getFullAddress, Option } from "helpers";

import DbiInput from "components/shared/DbiInput";
import DbiButton from "components/shared/DbiButton";
import BaseLayout from "components/portal/BaseLayout";
import DbiDropdown from "components/shared/DbiDropdown";
import TicketStatusChip from "components/shared/Chip/variants/TicketStatusChip";

import InfoBlocks from "./InfoBlocks/InfoBlocks";
import { DateBlock } from "./InfoBlocks/parts/DateBlock";
import { TimeBlock } from "./InfoBlocks/parts/TimeBlock";
import { TicketBlock } from "./InfoBlocks/parts/TicketBlock";

import classes from "./Tickets.module.css";
import { GET_ALL_TICKETS } from "const/queryNames";
import { DbiTable } from "components/shared/DbiTable";

const Tickets = () => {
    const { t } = useTranslation();
    const { getTickets } = useTicketResource();

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [ticketData, setTicketData] = useState<ITicket[]>([]);

    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });
    const [totalDataCount, setTotalDataCount] = useState(0);

    const { queryParams, setSearchTerms } = useQueryParams({
        amount: pagination.pageSize as PAGE_SIZES,
        skipFirst: pagination.pageIndex,
        sortedBy: "createdOn",
        direction: "desc",
    });

    const query = useQuery(
        [
            GET_ALL_TICKETS,
            queryParams.searchTerms,
            queryParams.searchTerm,
            queryParams.sortedBy,
            queryParams.direction,
            pagination.pageIndex,
            pagination.pageSize,
        ],
        async () => {
            for (const k in queryParams.searchTerms) {
                if (queryParams.searchTerms[k] == null || queryParams.searchTerms[k] === "") {
                    delete queryParams.searchTerms[k];
                }
            }
            return await getTickets({
                ...queryParams,
                amount: pagination.pageSize as PAGE_SIZES,
                skipFirst: pagination.pageIndex * pagination.pageSize,
            });
        }
    );

    useEffect(() => {
        if (query.data) {
            const data = query.data;
            setTicketData(data.data.tableData);
            setTotalDataCount(data.data.totalRecords);
        }
    }, [query.data, pagination]);

    const emptyStatusNumber: number = 1000;

    const statusOptions: Option[] = useMemo(
        () => [
            { value: emptyStatusNumber, label: t("ticket.table.statusPlaceholder") },
            { value: ITicketStatus.Open, label: t("tickets.status.0") },
            { value: ITicketStatus.Approved, label: t("tickets.status.1") },
            { value: ITicketStatus.PendingByDBI, label: t("tickets.status.2") },
            { value: ITicketStatus.PendingByMunicipality, label: t("tickets.status.3") },
        ],
        [t]
    );

    const columns = useMemo<MRT_ColumnDef<ITicket>[]>(
        () => [
            {
                header: t("ticket.table.ticketCode") as string,
                accessorKey: "ticketCode",
            },
            {
                header: t("ticket.table.municipality") as string,
                accessorKey: "address.municipality.name",
            },
            {
                header: t("ticket.table.createdOn") as string,
                accessorKey: "createdOn",
                Cell: ({ renderedCellValue }) => formatDateTime(renderedCellValue as string),
            },
            {
                header: t("ticket.table.address") as string,
                accessorKey: "address",
                Cell: ({ renderedCellValue }) => getFullAddress(renderedCellValue as any),
            },
            {
                header: t("ticket.table.summary") as string,
                accessorKey: "summary",
            },
            {
                header: t("ticket.table.status") as string,
                accessorFn: (row) => `${row.status}`,
                accessorKey: "status",
                Cell: ({ cell }) => {
                    let status = cell.getValue<ITicketStatus>() as number;
                    return <TicketStatusChip status={status} />;
                },
            },
        ],
        [t]
    );

    const handleRowClick = useCallback(
        (id: number) => {
            navigate(`/tickets/${id}`);
        },
        [navigate]
    );

    const openExportModal = useCallback(() => {
        dispatch(setCurrentModal("exportModal"));
    }, [dispatch]);

    // TODO: find a better way to use a default value for dropdowns
    const customDropDownLogic = (status: number) => {
        if (status === emptyStatusNumber) {
            setSearchTerms("status", "");
            return;
        }
        setSearchTerms("status", String(status));
    };

    const { data: ticketApprovedData } = useQuery({
        queryKey: [GET_ALL_TICKETS],
        queryFn: async () =>
            await getTickets({
                ...queryParams,
                searchTerms: {
                    status: "1",
                },
                amount: 5,
                skipFirst: 0,
            }),
    });

    return (
        <BaseLayout
            breadcrumb={t("breadcrumb.tickets")}
            buttons={
                <>
                    {/* @TODO show buttons when their function is finished */}
                    {/* <DbiButton variant={"primary"} label={t("tickets.serviceRoundButton")} />*/}
                    <DbiButton variant={"primary"} onClick={openExportModal} label={t("tickets.exportButton")} />
                    <Link to="/tickets/add-ticket" className="button button--primary">
                        {t("tickets.addButton")}
                    </Link>
                </>
            }
        >
            <InfoBlocks
                blocks={() => (
                    <>
                        <DateBlock />
                        <TimeBlock />
                        <TicketBlock
                            customContent={`${ticketApprovedData?.data.totalRecords || 0} / ${totalDataCount}`}
                        />
                    </>
                )}
            />
            <div className={classes.filter_group}>
                <DbiInput
                    type="search"
                    onChange={(event) => setSearchTerms("ticketCode", event.target.value)}
                    placeholder={t("ticket.table.ticketCode")}
                    hasDebounce={true}
                    searchIcon={true}
                />
                <DbiInput
                    type="search"
                    onChange={(event) => setSearchTerms("address.postalCode", event.target.value)}
                    placeholder={t("ticket.table.zipCode")}
                    hasDebounce={true}
                    searchIcon={true}
                />
                <DbiInput
                    type="search"
                    onChange={(event) => setSearchTerms("address.houseNumber", event.target.value)}
                    placeholder={t("ticket.table.houseNumber")}
                    hasDebounce={true}
                    searchIcon={true}
                />
                <DbiInput
                    type="search"
                    onChange={(event) => setSearchTerms("address.street.city.name", event.target.value)}
                    placeholder={t("ticket.table.city")}
                    hasDebounce={true}
                    searchIcon={true}
                />
                <DbiDropdown
                    type="search"
                    options={statusOptions}
                    value={emptyStatusNumber}
                    onChange={(status: number) => customDropDownLogic(status)}
                />
            </div>

            <DbiTable
                columns={columns}
                onRowClick={handleRowClick}
                data={ticketData}
                options={{
                    enablePagination: true,
                }}
                setPagination={setPagination}
                pagination={pagination}
                dataCount={totalDataCount}
                contentName={t("table.tickets")}
            />
        </BaseLayout>
    );
};

export default Tickets;
