import clsx from "clsx";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { IWheelieBin } from "types/WheelieBin";
import { formatDate, getStatusColor, getWheeliebinImage } from "helpers";

import Card from "components/shared/Card/index";

import classes from "./ContainerCard.module.css";

const ContainerCard = ({ className, bin, active }: { className?: string; bin: IWheelieBin; active?: string }) => {
    const { t } = useTranslation();
    const [statusColor, setStatusColor] = useState<string>();

    useEffect(() => {
        const color = getStatusColor(bin.status);
        setStatusColor(color);
    }, [bin.status]);

    return (
        <Card className={clsx(className, { [classes[`${active}`]]: active })}>
            <img className={classes.binImage} src={getWheeliebinImage(bin)} alt="wheeliebin" />
            <div className={classes.content}>
                <div className={clsx(classes.bin_name, classes.flexRow)}>
                    {bin.name}
                    <span style={{ color: statusColor }}>{t("wheeliebin.status." + bin.status)}</span>
                </div>
                <div className={classes.bin_timestamp}>
                    {t("ticketDetail.containers.since", {
                        date: formatDate(bin.createdOn),
                    })}
                </div>
            </div>
        </Card>
    );
};

export { ContainerCard as default };
