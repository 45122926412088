import Cookies from "js-cookie";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { useAppDispatch } from "hooks";
import { setAccessToken } from "store/slices/AuthSlice";
import NotificationsContainer from "components/shared/NotificationsContainer";

// styles
import classes from "./PrivateLayout.module.css";

const PrivateLayout = () => {
    const nav = useNavigate();
    const dispatch = useAppDispatch();
    const accessToken = Cookies.get("jwt-dbi");
    const rememberTwoFactorToken = Cookies.get("remember-2fa-dbi");

    useEffect(() => {
        if (accessToken) {
            try {
                dispatch(setAccessToken({ accessToken, rememberTwoFactorToken }));
            } catch (error) {
                console.warn("cannot set token");
            }
        } else {
            nav("/auth/login");
        }
    }, [accessToken, dispatch, nav, rememberTwoFactorToken]);

    return (
        <div className={classes.main} id={"private_layout"}>
            <Outlet />
            <NotificationsContainer />
        </div>
    );
};

export default PrivateLayout;
