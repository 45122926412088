import { AxiosError } from "axios";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { useTicketResource } from "api/resources";

import { IWheelieBin } from "types/WheelieBin";
import { IValidationMessages } from "types/ValidationMessages";
import { IAddressDetailViewModel } from "../../../types/Address";
import {
    ICreateTicket,
    ITicket,
    ITicketSubjectItem,
    ITicketStatus,
    ITicketSubject,
    ITicketObjectType,
    IBringLocation,
    IUndergroundContainerViewModel,
} from "types";

import { ticketObjectList } from "const/TicketObjects";
import { mapDataToSelectOptions } from "helpers/selectOptions";

import DbiInput from "components/shared/DbiInput";

import DbiButton from "components/shared/DbiButton";
import BaseLayout from "components/portal/BaseLayout";
import DbiTextArea from "components/shared/DbiTextArea";
import DbiDropdown from "components/shared/DbiDropdown";

import classes from "./CreateTicket.module.css";
import { useQuery } from "@tanstack/react-query";
import { GET_ADDRESS, GET_CARDS, GET_CONTAINERS, GET_TICKETS_BY_ADDRESS, GET_BRING_LOCATIONS } from "const/queryNames";
import { ContainerBox } from "components/shared/ContainerBox";
import { DbiTable } from "components/shared/DbiTable";
import { MRT_ColumnDef } from "material-react-table";
import { formatDate, formatDateTime, getStatusColor } from "helpers";
import TicketStatusChip from "components/shared/Chip/variants/TicketStatusChip";
import { ICard } from "types";
import DbiCheckbox from "components/shared/DbiCheckbox";

const defaultTicket: ICreateTicket = {
    addressId: 0,
    actionRemark: null,
    description: null,
    subjectId: null,
    subjectItemIds: [],
    objectType: null,
    objectId: null,
};

const CreateTicket = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();

    const {
        createTicket,
        getAddressDetail,
        getTicketsForAddress,
        getContainersForAddresses,
        getTicketSubjects,
        getCardsForAddress,
        getUcContaintersForAddress,
    } = useTicketResource();

    const [tempTicket, setTempTicket] = useState<ICreateTicket>(defaultTicket);

    // const [knownTickets, setKnownTickets] = useState<ITicket[]>([]);
    const [validationMessages, setValidationMessages] = useState<IValidationMessages>();
    const [addressStateData, setAddressStateData] = useState<IAddressDetailViewModel>();
    const [containerStateData, setContainerStateData] = useState<IWheelieBin[]>([]);
    const [ticketStateData, setTicketStateData] = useState<ITicket[]>([]);
    const [ticketSubjectStateData, setTicketSubjectStateData] = useState<ITicketSubject[]>([]);
    const [cardsStateData, setCardsStateData] = useState<ICard[]>([]);
    const [currentActions, setCurrentActions] = useState<ITicketSubjectItem[] | undefined>(undefined);
    const [bringLocationStateData, setBringLocationStateData] = useState<IBringLocation[]>([]);
    const [bringLocationId, setBringLocationId] = useState(0);
    const [currentContainer, setCurrentContainers] = useState<IUndergroundContainerViewModel[]>();

    // DATA FETCHING
    const { data: addressData } = useQuery({
        queryKey: [GET_ADDRESS, Number(id)],
        queryFn: async () => {
            const data = await getAddressDetail(Number(id));
            return data;
        },
        enabled: !!id,
    });

    const { data: containerData } = useQuery({
        queryKey: [GET_CONTAINERS, Number(id)],
        queryFn: async () => {
            const id = addressData?.data.addressId;
            if (id) {
                const data = await getContainersForAddresses({}, id);
                return data;
            } else {
                throw new Error("Network response was not ok");
            }
        },
        enabled: !!addressData?.data.addressId,
    });

    const { data: ticketData } = useQuery({
        queryKey: [GET_TICKETS_BY_ADDRESS, Number(id)],
        queryFn: async () => {
            const id = addressData?.data.addressId;
            if (id) {
                const data = await getTicketsForAddress({}, addressData?.data.addressId);
                return data;
            } else {
                throw new Error("Network response was not ok");
            }
        },
        enabled: !!addressData?.data.addressId,
    });

    const { data: ticketSubjectData } = useQuery({
        queryKey: [],
        queryFn: async () => {
            const municipalityId = addressData?.data.municipality.id || 0;
            if (id) {
                const data = await getTicketSubjects({}, municipalityId);
                return data;
            } else {
                throw new Error("Network response was not ok");
            }
        },
        enabled: !!addressData?.data.municipality.id,
    });

    const { data: cardData } = useQuery({
        queryKey: [GET_CARDS, Number(id)],
        queryFn: async () => {
            const id = addressData?.data.addressId;
            if (id) {
                const data = await getCardsForAddress({}, addressData?.data.addressId);
                return data;
            } else {
                throw new Error("Network response was not ok");
            }
        },
        enabled: !!addressData?.data.addressId,
    });

    const { data: bringLocationData } = useQuery({
        queryKey: [GET_BRING_LOCATIONS, Number(id)],
        queryFn: async () => {
            const id = addressData?.data.addressId;
            if (id) {
                const data = await getUcContaintersForAddress(
                    {
                        amount: 10,
                    },
                    addressData?.data.addressId
                );
                return data;
            } else {
                throw new Error("Network response was not ok");
            }
        },
        enabled: !!addressData?.data.addressId,
    });

    useEffect(() => {
        if (addressData?.data) {
            setAddressStateData(addressData.data);
            setTempTicket((prevState) => ({ ...prevState, addressId: addressData.data.addressId }));
        }
    }, [addressData?.data]);

    useEffect(() => {
        if (containerData?.data) {
            setContainerStateData(containerData?.data.tableData);
        }
    }, [containerData?.data]);

    useEffect(() => {
        if (ticketData?.data) {
            setTicketStateData(ticketData?.data.tableData);
        }
    }, [ticketData?.data]);

    useEffect(() => {
        if (ticketSubjectData?.data) {
            setTicketSubjectStateData(ticketSubjectData?.data.tableData);
        }
    }, [ticketSubjectData?.data]);

    useEffect(() => {
        if (cardData?.data) {
            setCardsStateData(cardData.data.tableData);
        }
    }, [cardData?.data]);

    useEffect(() => {
        if (bringLocationData?.data) {
            setBringLocationStateData(bringLocationData.data.tableData);
        }
    }, [bringLocationData]);

    const columns = useMemo<MRT_ColumnDef<ITicket>[]>(
        () => [
            {
                header: t("ticket.table.createdOn") as string,
                accessorKey: "createdOn",
                Cell: ({ renderedCellValue }) => formatDateTime(renderedCellValue as string),
            },
            {
                header: t("ticket.table.summary") as string,
                accessorKey: "summary",
            },
            {
                header: t("ticket.table.status") as string,
                accessorFn: (row) => `${row.status}`,
                accessorKey: "status",
                Cell: ({ cell }) => {
                    let status = cell.getValue<ITicketStatus>() as number;
                    return <TicketStatusChip status={status} />;
                },
            },
        ],
        [t]
    );

    // set the new actions for the selected subject and clear the current actions
    useEffect(() => {
        setTempTicket((prevState) => ({ ...prevState, subjectItemIds: [] }));
        if (!tempTicket.subjectId) return;
        setValidationMessages({ subjectId: [""] });
        const currentSubject = ticketSubjectStateData.find((sub) => sub.id === tempTicket.subjectId);
        const items = currentSubject?.items || undefined;
        setCurrentActions(items);
    }, [tempTicket.subjectId, ticketSubjectStateData]);

    useEffect(() => {
        if (bringLocationStateData?.length <= 0 && bringLocationId === 0) return;
        const b = bringLocationStateData.find((b) => b.id === bringLocationId);
        if (!b) return;
        const containers = b.containers;
        setCurrentContainers(containers);
    }, [bringLocationId, bringLocationStateData]);

    useEffect(() => {
        if (!tempTicket.phoneNumber) return;
        const regex = new RegExp(/^\d{10}$/);
        tempTicket.phoneNumber?.match(regex)
            ? setValidationMessages({ phoneNumber: [""] })
            : setValidationMessages({ phoneNumber: [t(`errors.phonenumber`)] });
    }, [tempTicket.phoneNumber, t]);

    const saveTicket = () => {
        if (tempTicket) {
            if (!tempTicket.subjectId) {
                setValidationMessages({ subjectId: [t(`errors.subjectId`)] });
                return;
            }

            const submitTicket = { ...tempTicket };

            delete submitTicket.subjectId;

            createTicket(submitTicket)
                .then((resp) => {
                    if (resp.data) {
                        navigate(`/tickets/${resp.data}`, { replace: true });
                    }
                })
                .catch((error: AxiosError) => {
                    if (error.response?.status === 400) {
                        setValidationMessages(error.response.data);
                    }
                });
        }
    };

    const translateObjectTypes = ticketObjectList.map((item) => ({
        value: item.value,
        text: t(`ticket.objectType.${item.text}`),
    }));

    const isLabelChecked = (id: number) => {
        if (!id && !tempTicket.subjectItemIds) return false;
        return tempTicket.subjectItemIds?.includes(id) ? true : false;
    };

    const setChecked = (actionId: number) => {
        const actionIds = [...(tempTicket.subjectItemIds || [])];
        const actionIndex = actionIds.findIndex((id) => id === actionId);

        if (actionIndex !== -1) {
            actionIds.splice(actionIndex, 1);
        } else {
            actionIds.push(actionId);
        }

        setTempTicket((prevState) => ({ ...prevState, subjectItemIds: actionIds }));
    };

    return (
        <BaseLayout breadcrumb={t("breadcrumb.createTicket")}>
            <div className={classes.content}>
                <div className={classes.wrap}>
                    <div className={classes.questions}>
                        <DbiDropdown
                            usePlaceHolder={{
                                value: Number.MAX_SAFE_INTEGER,
                                label: t("createTicket.subject.placeHolder"),
                            }}
                            label={t("createTicket.subject")}
                            value={tempTicket.subjectId}
                            onChange={(val) => setTempTicket((prevState) => ({ ...prevState, subjectId: val }))}
                            options={mapDataToSelectOptions(ticketSubjectStateData, "id", "title")}
                            validationMessage={validationMessages?.subjectId}
                        />

                        {/* Only show these field when user selected a subject */}
                        {tempTicket.subjectId && (
                            <>
                                <div className={classes.actionsWrapper}>
                                    <div className={"dbi-input-field__label " + classes.actionTitle}>
                                        {t("createTicket.action")}
                                    </div>
                                    <div className={classes.actions}>
                                        {currentActions?.map((action: ITicketSubjectItem) => (
                                            <DbiCheckbox
                                                key={action.id}
                                                labelAfter={true}
                                                label={action.title}
                                                checked={isLabelChecked(action.id)}
                                                onChange={() => setChecked(action.id)}
                                            />
                                        ))}
                                    </div>
                                    {validationMessages && validationMessages.subjectItemIds ? (
                                        <div className="dbi_validation_message">
                                            {validationMessages.subjectItemIds}
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </div>

                                <DbiTextArea
                                    label={t("createTicket.actionRemark")}
                                    onChange={(e) =>
                                        setTempTicket((prevState) => ({
                                            ...prevState,
                                            actionRemark: e.target.value,
                                        }))
                                    }
                                    validationMessage={validationMessages?.actionRemark}
                                />

                                <DbiDropdown
                                    label={t("createTicket.objectType")}
                                    value={tempTicket.objectType}
                                    usePlaceHolder={{
                                        value: Number.MAX_SAFE_INTEGER,
                                        label: t("createTicket.objectType.placeHolder"),
                                    }}
                                    onChange={(val) =>
                                        setTempTicket((prevState) => ({ ...prevState, objectType: val }))
                                    }
                                    options={mapDataToSelectOptions(translateObjectTypes, "value", "text")}
                                    validationMessage={validationMessages?.objectType}
                                />

                                {tempTicket.objectType === ITicketObjectType.Card && (
                                    <DbiDropdown
                                        label={t("createTicket.cards")}
                                        value={tempTicket.objectId}
                                        usePlaceHolder={{
                                            value: Number.MAX_SAFE_INTEGER,
                                            label: t("createTicket.card.placeHolder"),
                                        }}
                                        onChange={(val) =>
                                            setTempTicket((prevState) => ({ ...prevState, objectId: val }))
                                        }
                                        options={mapDataToSelectOptions(cardsStateData, "cardId", "internalCardNumber")}
                                        validationMessage={validationMessages?.objectType}
                                    />
                                )}

                                {tempTicket.objectType === ITicketObjectType.WheelieBin && (
                                    <DbiDropdown
                                        label={t("createTicket.containers")}
                                        value={tempTicket.objectId}
                                        usePlaceHolder={{
                                            value: Number.MAX_SAFE_INTEGER,
                                            label: t("createTicket.container.placeHolder"),
                                        }}
                                        onChange={(val) =>
                                            setTempTicket((prevState) => ({ ...prevState, objectId: val }))
                                        }
                                        customOptions={containerStateData}
                                        validationMessage={validationMessages?.objectType}
                                        customOptionRow={(option: IWheelieBin, handleChange) => {
                                            const color = getStatusColor(option.status);
                                            return (
                                                <div
                                                    className={classes.option}
                                                    onClick={() =>
                                                        handleChange({ label: option.name, value: option.wheelieBinId })
                                                    }
                                                    key={option.wheelieBinId}
                                                >
                                                    <span className={classes.option__name}>{option.name}</span>
                                                    <span>{" - "}</span>
                                                    <span>{formatDate(option.createdOn)}</span>
                                                    <span>{" - "}</span>
                                                    <span className={classes.option__status} style={{ color: color }}>
                                                        {t("wheeliebin.status." + option.status)}
                                                    </span>
                                                </div>
                                            );
                                        }}
                                    />
                                )}

                                {tempTicket.objectType === ITicketObjectType.UndergroundContainer && (
                                    <>
                                        <DbiDropdown
                                            label={t("createTicket.bringLocations")}
                                            value={bringLocationId}
                                            usePlaceHolder={{
                                                value: Number.MAX_SAFE_INTEGER,
                                                label: t("createTicket.bringLocation.placeHolder"),
                                            }}
                                            onChange={(val) => setBringLocationId(val)}
                                            options={mapDataToSelectOptions(
                                                bringLocationStateData,
                                                "id",
                                                "description"
                                            )}
                                            validationMessage={validationMessages?.objectType}
                                        />

                                        {bringLocationId !== null && currentContainer && (
                                            <DbiDropdown
                                                label={t("createTicket.ucContainers")}
                                                value={tempTicket.objectId}
                                                usePlaceHolder={{
                                                    value: Number.MAX_SAFE_INTEGER,
                                                    label: t("createTicket.undergroundContainer.placeHolder"),
                                                }}
                                                onChange={(val) =>
                                                    setTempTicket((prevState) => ({ ...prevState, objectId: val }))
                                                }
                                                options={mapDataToSelectOptions(currentContainer, "id", "name")}
                                                validationMessage={validationMessages?.objectType}
                                            />
                                        )}
                                    </>
                                )}

                                <DbiTextArea
                                    label={t("createTicket.description")}
                                    onChange={(e) =>
                                        setTempTicket((prevState) => ({ ...prevState, description: e.target.value }))
                                    }
                                    validationMessage={validationMessages?.description}
                                />
                            </>
                        )}

                        <h5>{t("createTicket.customerContactDetails")}</h5>
                        <DbiInput
                            label={t("createTicket.emailAddress")}
                            type="email"
                            onChange={(e) =>
                                setTempTicket((prevState) => ({ ...prevState, emailAddress: e.target.value }))
                            }
                            validationMessage={validationMessages?.emailAddress}
                        />
                        <DbiInput
                            label={t("createTicket.phoneNumber")}
                            type="text"
                            onChange={(e) =>
                                setTempTicket((prevState) => ({
                                    ...prevState,
                                    phoneNumber: e.target.value,
                                }))
                            }
                            validationMessage={validationMessages?.phoneNumber}
                        />
                        <div className={classes.buttons}>
                            <DbiButton label={t("createTicket.saveButton")} onClick={saveTicket} />
                        </div>
                    </div>
                    <div className={classes.tickets}>
                        <h3>{t("createTicket.logs.title")}</h3>
                        <DbiTable data={ticketStateData} columns={columns} />
                    </div>
                </div>

                <div className={classes.blocks}>
                    {addressStateData && (
                        <ContainerBox
                            address={addressStateData}
                            containers={containerStateData}
                            cards={cardsStateData}
                        />
                    )}
                </div>
            </div>
        </BaseLayout>
    );
};

export default CreateTicket;
