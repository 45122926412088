import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import { MRT_ColumnDef } from "material-react-table";

import { formatDateTime } from "helpers";
import { IStatus, ITicketStatus } from "types/Ticket";

import { DbiTable } from "components/shared/DbiTable";
import TicketStatusChip from "components/shared/Chip/variants/TicketStatusChip";

import { useTicketData } from "../../useTicketData";
import classes from "./TicketLog.module.scss";

const TicketStatusLog = () => {
    const { t } = useTranslation();
    const { ticketStateData: ticketData } = useTicketData();

    const [ticketStatusLog, setTicketStatuLog] = useState<IStatus<ITicketStatus>[]>([]);

    useEffect(() => {
        if (ticketData && ticketData.statusLog) {
            setTicketStatuLog(ticketData.statusLog);
        }
    }, [ticketData]);

    const columns = useMemo<MRT_ColumnDef<IStatus<ITicketStatus>>[]>(
        () => [
            {
                header: t("tickets.log.user") as string,
                accessorKey: "user.name",
            },
            {
                header: t("ticketDetail.remarks.table.comments") as string,
                accessorKey: "status",
                Cell: ({ cell }) => {
                    let status = cell.getValue<ITicketStatus>() as number;
                    return <TicketStatusChip status={status} />;
                },
            },
            {
                accessorKey: "reason",
                header: t("tickets.log.reason") as string,
            },
            {
                header: t("ticketDetail.remarks.table.createdOn") as string,
                accessorKey: "occurredOn",
                Cell: ({ renderedCellValue }) => formatDateTime(renderedCellValue as string),
            },
        ],
        [t]
    );

    return (
        <div className={classes.block}>
            <DbiTable columns={columns} data={ticketStatusLog} contentName={t("table.logs")} />
        </div>
    );
};

export default TicketStatusLog;
