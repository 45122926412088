import clsx from "clsx";
import { formatTimeShort } from "helpers";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classes from "../InfoBlocks.module.scss";

type Props = {
    customContent?: string;
};

export const TimeBlock = (props: Props) => {
    const { t } = useTranslation();
    const [time, setTime] = useState(new Date().getTime());

    useEffect(() => {
        setInterval(() => {
            setTime(new Date().getTime());
        }, 1000);
    }, []);

    return (
        <div className={classes.block}>
            <i className={clsx("icons8-system-task", classes.icon_big)} />
            <i className={clsx("icons8-system-task", classes.icon_small)} />
            <div className={classes.title}>{formatTimeShort(time)}</div>
            <div className={classes.content}>{t("tickets.openTickets.time")}</div>
        </div>
    );
};
