import { useCallback } from "react";

import { useAppDispatch, useAppSelector } from "./useStore";
import { addNotification, dismissNotification, INewNotification } from "store/slices/NotificationsSlice";

export const useNotifications = () => {
    const dispatch = useAppDispatch();

    const notifications = useAppSelector((state) => state.notifications.notifications);

    const add = useCallback(
        (body: INewNotification) => {
            dispatch(addNotification(body));
        },
        [dispatch]
    );

    const dismiss = useCallback(
        (id: number) => {
            dispatch(dismissNotification(id));
        },
        [dispatch]
    );

    return { add, dismiss, notifications };
};
