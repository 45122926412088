import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Helmet, HelmetProvider } from "react-helmet-async";
import ErrorBoundary from "../ErrorBoundary";
import store from "store/store";
import App from "components/shared/App";
import "locale";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Modal from "../../../modals";

const rootPageTitle = "KCC Binfinity";

export const client = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: true,
            refetchOnReconnect: true,
        },
    },
});
const Root = () => {
    return (
        <Provider store={store}>
            <HelmetProvider>
                <Helmet titleTemplate={`%s – ${rootPageTitle}`} defaultTitle={rootPageTitle} />
                <BrowserRouter>
                    <ErrorBoundary>
                        <QueryClientProvider client={client}>
                            <App />
                            <Modal />
                            <ReactQueryDevtools />
                        </QueryClientProvider>
                    </ErrorBoundary>
                </BrowserRouter>
            </HelmetProvider>
        </Provider>
    );
};

export default Root;
