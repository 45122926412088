import classes from "./DbiLoader.module.css";

const DbiLoader = () => {
    return (
        <div className={classes.loader}>
            <svg className={classes.icon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48">
                <path d="M11 3a2 2 0 0 0-2 2 2 2 0 0 0 2 2 2 2 0 0 0 2-2 2 2 0 0 0-2-2zm6.5 2A2.5 2.5 0 0 0 15 7.5a2.5 2.5 0 0 0 2.5 2.5A2.5 2.5 0 0 0 20 7.5 2.5 2.5 0 0 0 17.5 5zm-12 1A1.5 1.5 0 0 0 4 7.5 1.5 1.5 0 0 0 5.5 9 1.5 1.5 0 0 0 7 7.5 1.5 1.5 0 0 0 5.5 6zm-1 5.984a1.5 1.5 0 0 0-1.5 1.5 1.5 1.5 0 0 0 1.5 1.5 1.5 1.5 0 0 0 1.5-1.5 1.5 1.5 0 0 0-1.5-1.5zM19 12a1 1 0 0 0-1 1 1 1 0 0 0 1 1 1 1 0 0 0 1-1 1 1 0 0 0-1-1zM6.5 17A1.5 1.5 0 0 0 5 18.5 1.5 1.5 0 0 0 6.5 20 1.5 1.5 0 0 0 8 18.5 1.5 1.5 0 0 0 6.5 17zM17 17a1 1 0 0 0-1 1 1 1 0 0 0 1 1 1 1 0 0 0 1-1 1 1 0 0 0-1-1zm-5 2a1 1 0 0 0-1 1 1 1 0 0 0 1 1 1 1 0 0 0 1-1 1 1 0 0 0-1-1z" />
            </svg>
        </div>
    );
};

export default DbiLoader;
