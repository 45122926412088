import { useCallback, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { useOnClickOutside } from "usehooks-ts";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import { useAppDispatch, useAppSelector } from "hooks";
import { logout } from "store/slices/AuthSlice";
import { IDecodedToken } from "types";

import classes from "./UserInfo.module.css";
import ddClass from "../DbiDropdown/DbiDropdown.module.scss";
import LanguageSwitch from "../LanguageSwitch";

const UserInfo = () => {
    const userInfo: IDecodedToken | undefined = useAppSelector((state) => state.auth.token);
    const dispatch = useAppDispatch();

    const [dropdown, setDropdown] = useState(false);
    const { t } = useTranslation();
    const nodeRef = useRef(null);
    const ref = useRef(null);
    const nav = useNavigate();

    useOnClickOutside(ref, () => setDropdown(false));

    const handleSignOut = useCallback(() => {
        setDropdown(false);
        dispatch(logout());
        nav("/auth/login");
    }, [dispatch, nav]);

    const handleShowDropdown = useCallback(() => {
        setDropdown((v) => !v);
    }, []);

    return (
        <div className={classes.user_block} ref={ref}>
            <div>
                <LanguageSwitch />
            </div>
            <div className={classes.account_info}>
                <span>{userInfo && userInfo["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"]}</span>
            </div>
            <i className={clsx("icons8-user-menu-male", classes.icon)} onClick={handleShowDropdown}></i>

            <CSSTransition unmountOnExit in={dropdown} nodeRef={nodeRef} timeout={0}>
                <div className={ddClass.dropdown_content}>
                    <div className={ddClass.option} onClick={handleSignOut}>
                        {t("auth.signOut")}
                    </div>
                </div>
            </CSSTransition>
        </div>
    );
};

export default UserInfo;
