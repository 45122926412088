import { useTranslation } from "react-i18next";
import { ComponentProps, useMemo } from "react";

import { ITicketStatus } from "types/Ticket";

import DbiButton from "../../DbiButton";

type Props = {
    status: ITicketStatus | undefined;
};

const TicketStatusButton = ({ status, ...rest }: Omit<ComponentProps<typeof DbiButton>, "label"> & Props) => {
    const { t } = useTranslation();

    const variant: typeof rest.variant = useMemo(() => {
        switch (status) {
            case ITicketStatus.Approved:
                return "success";
            case ITicketStatus.Open:
                return "warning";
            case ITicketStatus.PendingByDBI:
            case ITicketStatus.PendingByMunicipality:
            default:
                return "primary";
        }
    }, [status]);

    const label = useMemo(() => {
        return `${t("ticket.table.status")}: ${t(`tickets.status.${status}`)}`;
    }, [status, t]);

    if (status !== undefined && status >= 0) {
        return <DbiButton {...rest} variant={variant} label={label} />;
    }

    return null;
};

export default TicketStatusButton;
