import { AxiosResponse } from "axios";
import useClient from "api/useClient";
import { IReport } from "types";
import { saveAs } from "file-saver";

type ContactCenterReportResource = {
    downloadCSVReport: (report: IReport) => void;
    getReports: () => Promise<AxiosResponse<IReport[]>>;
};

export const useContactCenterReportResource = (): ContactCenterReportResource => {
    const client = useClient();

    const getReports = () => {
        return client.get<IReport[]>("ContactCenterReport");
    };

    const downloadCSVReport = async (report: IReport) => {
        const { title, name } = report;

        let response = await client.get(`ContactCenterReport/CSV/${name}`, {
            responseType: "arraybuffer",
        });

        saveAs(
            new Blob([response.data], {
                type: response.headers["content-type"] || "application/octet-stream;charset=binary",
            }),
            title
        );
    };

    return {
        downloadCSVReport,
        getReports,
    };
};
