import { IConfig } from "types";

const localConfig = {
    apiUrl: process.env.REACT_APP_API_URL,
    appInsights: {
        instrumentationKey: process.env.APP_INSIGHTS_INSTRUMENTATION_KEY || null,
    },
    citizenOpenContainer: false,
    google: {
        apiKey: process.env.GOOGLE_API_KEY,
    },
    icbisUrl: process.env.REACT_APP_ICBIS_URL,
} as IConfig;

export default {
    apiUrl: localConfig.apiUrl,
    appInsights: localConfig.appInsights || {},
    citizenOpenContainer: localConfig.citizenOpenContainer || false,
    google: {
        apiKey: localConfig.google ? localConfig.google.apiKey : "",
    },
    icbisUrl: localConfig.icbisUrl,
} as IConfig;
