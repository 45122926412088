import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { ITicket, ITicketStatus } from "types/Ticket";
import { useMemo, useState, useEffect } from "react";
import { MRT_ColumnDef } from "material-react-table";

import { formatDateTime } from "helpers";
import { useTicketResource } from "api/resources";
import { GET_TICKETS_BY_ADDRESS } from "const/queryNames";

import TicketStatusChip from "components/shared/Chip/variants/TicketStatusChip";
import { DbiTable } from "components/shared/DbiTable";

import classes from "./TicketIssueLog.module.css";

const TicketIssueLog = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { getTicketsForAddress } = useTicketResource();
    const [ticketLogs, setTicketLogs] = useState<ITicket[]>([]);

    const { data: ticketLogData } = useQuery({
        queryKey: [GET_TICKETS_BY_ADDRESS, Number(id)],
        queryFn: async () => {
            const data = await getTicketsForAddress({}, Number(id));
            return data;
        },
        enabled: !!id,
    });

    useEffect(() => {
        if (ticketLogData?.data) {
            setTicketLogs(ticketLogData?.data.tableData);
        }
    }, [ticketLogData?.data]);

    const columns = useMemo<MRT_ColumnDef<ITicket>[]>(
        () => [
            {
                header: t("ticket.table.createdBy") as string,
                maxSize: 25,
                accessorKey: "createdBy",
            },
            {
                header: t("ticket.table.ticketCode") as string,
                accessorKey: "ticketCode",
            },
            {
                header: t("ticket.table.status") as string,
                accessorFn: (row) => `${row.status}`,
                accessorKey: "status",
                Cell: ({ cell }) => {
                    let status = cell.getValue<ITicketStatus>() as number;
                    return <TicketStatusChip status={status} />;
                },
            },
            {
                header: t("ticket.table.municipality") as string,
                accessorKey: "address.municipality.name",
            },
            {
                header: t("ticket.table.summary") as string,
                accessorKey: "summary",
            },
            {
                header: t("ticket.table.createdOn") as string,
                accessorKey: "createdOn",
                Cell: ({ renderedCellValue }) => formatDateTime(renderedCellValue as string),
            },
        ],
        [t]
    );

    return (
        <div className={classes.block}>
            <DbiTable columns={columns} data={ticketLogs} contentName={t("table.tickets")} />
        </div>
    );
};

export default TicketIssueLog;
