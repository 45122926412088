import { CardStatus } from "types";

export function getCardStatusColor(status: CardStatus): string {
    switch (status) {
        case CardStatus.Blocked:
            return "red";
        case CardStatus.Active:
            return "green";
        default:
            throw new Error(`Invalid wheelie bin status: ${status}`);
    }
}
