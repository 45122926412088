import { MouseEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useQueryParams } from "hooks";
import BaseLayout from "components/portal/BaseLayout";
import DbiButton from "components/shared/DbiButton";
import DbiInput from "components/shared/DbiInput";

import { GET_ADDRESSES } from "const/queryNames";
import { useTicketResource } from "api/resources";
import { IAddressViewModel, PAGE_SIZES } from "types";

import classes from "./SelectAddress.module.css";
import { DbiTable } from "components/shared/DbiTable";
import { MRT_ColumnDef, MRT_PaginationState } from "material-react-table";
import { getFullHouseNumber } from "helpers";

const SelectAddress = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [search, setSearch] = useState("");
    const { getAddresses } = useTicketResource();
    const [addressData, setAddressData] = useState<IAddressViewModel[]>([]);

    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });
    const [totalDataCount, setTotalDataCount] = useState(0);

    const columns = useMemo<MRT_ColumnDef<IAddressViewModel>[]>(
        () => [
            {
                header: t("addressSelector.street") as string,
                accessorKey: "street.name",
            },
            {
                header: t("addressSelector.postalCode") as string,
                accessorKey: "postalCode",
            },
            {
                header: t("addressSelector.houseNumber") as string,
                accessorFn: (originalRow) => getFullHouseNumber(originalRow),
                id: "houseNumber",
            },
            {
                header: t("addressSelector.city") as string,
                accessorKey: "street.city.name",
            },
        ],
        [t]
    );

    const { queryParams, setSearchTerm } = useQueryParams({
        amount: pagination.pageSize as PAGE_SIZES,
        skipFirst: pagination.pageIndex,
    });

    const query = useQuery(
        [
            GET_ADDRESSES,
            queryParams.searchTerms,
            queryParams.searchTerm,
            queryParams.sortedBy,
            queryParams.direction,
            pagination.pageIndex,
            pagination.pageSize,
        ],
        async () => {
            for (const k in queryParams.searchTerms) {
                if (queryParams.searchTerms[k] == null || queryParams.searchTerms[k] === "") {
                    delete queryParams.searchTerms[k];
                }
            }
            return await getAddresses({
                ...queryParams,
                amount: pagination.pageSize as PAGE_SIZES,
                skipFirst: pagination.pageIndex * pagination.pageSize,
            });
        }
    );

    useEffect(() => {
        if (query.data) {
            const data = query.data;
            setAddressData(data.data.tableData);
            setTotalDataCount(data.data.totalRecords);
        }
    }, [query.data, pagination]);

    const submitForm = useCallback(
        (event: MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            setSearchTerm(search);
        },
        [search, setSearchTerm]
    );

    return (
        <BaseLayout breadcrumb={t("breadcrumb.createTicket")}>
            <form className={classes.form}>
                <h2>{t("addressSelector.searchTitle")}</h2>
                <div className={classes.fields}>
                    <DbiInput
                        autoFocus
                        placeholder={"1234AB 12, Aastraat 12"}
                        direction="row"
                        label={t("addressSelector.address")}
                        onChange={(e) => setSearch(e.target.value)}
                        type="search"
                        value={search}
                    />
                    <DbiButton
                        onClick={submitForm}
                        loading={query.isLoading}
                        label={t("addressSelector.searchButton")}
                    />
                </div>
            </form>
            <div className={classes.result}>
                <DbiTable
                    data={addressData}
                    dataCount={totalDataCount}
                    columns={columns}
                    idKey="addressId"
                    onRowClick={(id: number) => navigate(`${id}`)}
                    options={{
                        enablePagination: true,
                    }}
                    setPagination={setPagination}
                    pagination={pagination}
                    contentName={t("ticket.table.addresses")}
                />
            </div>
        </BaseLayout>
    );
};
export default SelectAddress;
