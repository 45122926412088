import { IAddressViewModel } from "./Address";
import { ICard } from "./Card";
import { ITicketObjectType } from "./TicketObject";
import { ITicketSubject, ITicketSubjectItem } from "./TicketSubject";
import { IUndergroundContainerViewModel } from "./UndergroundContainer";
import { IUserViewModel } from "./User";
import { IWheelieBin } from "./WheelieBin";

export interface ITicket {
    id: number;
    ticketCode: string;
    address: IAddressViewModel;
    createdOn: string;
    createdBy: string;
    summary: string;
    status: ITicketStatus;
}

export interface IStatus<T> {
    status: T;
    reason: string;
    occurredOn: string;
    userId: number;
    user: IUserViewModel;
}

export interface ITicketDetail extends ITicket {
    id: number;
    action: ITicketAction;
    inquiry: ITicketInquiry;
    description: string;
    statusLog: IStatus<ITicketStatus>[];
    closedOn: string;
    closedBy: IUserViewModel;
    remarks: ITicketRemark[];
    wheelieBin: IWheelieBin;
    card: ICard;
    undergroundContainer: IUndergroundContainerViewModel;
    actionRemark?: string;
    contactEmailAddress?: string;
    contactPhoneNumber?: string;
    objectType: ITicketObjectType;
    subject?: ITicketSubject;
    subjectItems: ITicketSubjectItem[];
}

export interface ITicketInquiry {
    content: string;
    id: number;
}

export interface ITicketRemark {
    createdOn: string;
    comments: string;
    id: number;
    user: string;
}

export interface ICreateTicket {
    addressId: number;
    actionRemark?: string | null;
    description?: string | null;
    emailAddress?: string;
    phoneNumber?: string;
    subjectId?: number | null;
    subjectItemIds?: number[];
    objectType?: ITicketObjectType | null;
    objectId?: number | null;
}

export interface IUpdateTicket extends ICreateTicket {
    id: number;
}

export interface ICreateTicketRemark {
    id: number;
    comments: string;
}

export enum ITicketStatus {
    Open = 0,
    Approved = 1,
    PendingByDBI = 2,
    PendingByMunicipality = 3,
}

export enum ITicketAction {
    ContainerDefect = 0,
    ClipMissing = 1,
    ContainerUndelivered = 2,
    StickerPastedWrong = 3,
    ContainerNotPickedUp = 4,
    Other = 99,
}
export { ITicketObjectType };
