import React, { Component, ReactNode } from "react";
import FullPageError from "components/portal/FullPageError";

type Props = {
    children: ReactNode;
};

type State = {
    hasError?: boolean;
};

export default class ErrorBoundary extends Component<Props, State> {
    state: State = {
        hasError: false,
    };

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        this.setState({ hasError: true });
        console.error("Uncaught error:", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return <FullPageError statusCode={"500"} onReturn={() => this.setState({ hasError: false })} />;
        }
        return this.props.children;
    }
}
