import clsx from "clsx";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { NavLink, Outlet, useNavigate, useParams } from "react-router-dom";

import { Option } from "helpers/selectOptions";
import { useTicketResource } from "api/resources";
import { GET_CARDS, GET_CONTAINERS, GET_TICKET } from "const/queryNames";
import { ITicketDetail, IUpdateTicket, ITicketStatus, IWheelieBin, IValidationMessages, ICard } from "types";

import Modal from "components/shared/Modal";
import DbiLoader from "components/shared/DbiLoader";
import DbiButton from "components/shared/DbiButton";
import BaseLayout from "components/portal/BaseLayout";
import DbiTextArea from "components/shared/DbiTextArea";
import DbiDropdown from "components/shared/DbiDropdown";
import EditTicketModal from "./parts/EditTicket/EditTicketModal";
import TicketStatusButton from "components/shared/DbiButton/variants/TicketStatusButton";

import classes from "./TicketOverview.module.scss";
import { client } from "components/shared/Root/Root";

const TicketOverview = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const nav = useNavigate();
    const {
        getTicketDetail,
        getContainersForAddresses,
        saveTicketStatus,
        updateTicket,
        deleteTicket,
        getCardsForAddress,
    } = useTicketResource();

    const [ticketStateData, setTicketStateData] = useState<ITicketDetail>();
    const [containerStateData, setContainerStateData] = useState<IWheelieBin[]>();
    const [cardsStateData, setCardsStateData] = useState<ICard[]>([]);
    const [statusMessage, setStatusMessage] = useState("");
    const [showStatus, setStatusShow] = useState(false);
    const [showEdit, setEditShow] = useState(false);
    const [showDelete, setDeleteShow] = useState(false);
    const [status, setStatus] = useState<ITicketStatus>();
    const [validationMessages, setValidationMessages] = useState<IValidationMessages>();

    // DATA FETCHING
    const { data: ticketData, isLoading } = useQuery({
        queryKey: [GET_TICKET, Number(id)],
        queryFn: async () => {
            const data = await getTicketDetail(Number(id));
            return data;
        },
    });

    const { data: containerData, isLoading: isContainerLoading } = useQuery({
        queryKey: [GET_CONTAINERS, Number(id)],
        queryFn: async () => {
            const id = ticketData?.data.address.addressId;
            if (id) {
                const data = await getContainersForAddresses({}, ticketData?.data.address.addressId);
                return data;
            } else {
                throw new Error("Network response was not ok");
            }
        },
        enabled: !!ticketData?.data.address,
    });

    const { data: cardData } = useQuery({
        queryKey: [GET_CARDS, Number(id)],
        queryFn: async () => {
            const id = ticketData?.data.address.addressId;
            if (id) {
                const data = await getCardsForAddress({}, ticketData?.data.address.addressId);
                return data;
            } else {
                throw new Error("Network response was not ok");
            }
        },
        enabled: !!ticketData?.data.address,
    });

    useEffect(() => {
        if (ticketData?.data) {
            setTicketStateData(ticketData.data);
        }
    }, [ticketData?.data]);

    useEffect(() => {
        if (containerData?.data) {
            setContainerStateData(containerData?.data.tableData);
        }
    }, [containerData?.data]);

    useEffect(() => {
        if (cardData?.data) {
            setCardsStateData(cardData.data.tableData);
        }
    }, [cardData?.data]);

    const statusOptions: Option[] = useMemo(
        () => [
            { value: ITicketStatus.Open, label: t("tickets.status.0") },
            { value: ITicketStatus.Approved, label: t("tickets.status.1") },
            { value: ITicketStatus.PendingByDBI, label: t("tickets.status.2") },
            { value: ITicketStatus.PendingByMunicipality, label: t("tickets.status.3") },
        ],
        [t]
    );

    // TODO: Fix save ticket
    async function saveStatus(): Promise<void> {
        try {
            await saveTicketStatus(Number(id), Number(status), statusMessage);
            setStatusShow(false);
            setStatusMessage("");
            await client.invalidateQueries({ queryKey: [GET_TICKET, Number(id)] });
        } catch (e) {
            const err = e as AxiosError;
            if (err.response?.status === 400) {
                setValidationMessages(err.response.data);
            }
        }
    }

    async function saveTicket(updatedTicket: IUpdateTicket) {
        try {
            await updateTicket(updatedTicket);
            setEditShow(false);
            await client.invalidateQueries({ queryKey: [GET_TICKET, Number(id)] });
        } catch (error) {
            const err = error as AxiosError;
            if (err.response?.status === 400) {
                setValidationMessages(err.response.data);
            }
        }
    }

    function handleDelete() {
        if (id) {
            deleteTicket(Number(id));
            nav("/tickets");
        }
    }

    return (
        <BaseLayout
            breadcrumb={"Tickets / " + ticketStateData?.ticketCode}
            buttons={
                <>
                    <TicketStatusButton onClick={() => setStatusShow(true)} status={ticketStateData?.status} />
                    <DbiButton onClick={() => setEditShow(true)} variant={"primary"} label={t("actions.edit")} />
                    <DbiButton onClick={() => setDeleteShow(true)} variant={"red"} label={t("actions.delete")} />
                </>
            }
        >
            <>
                {ticketStateData && (
                    <EditTicketModal
                        show={showEdit}
                        ticket={ticketStateData}
                        containers={containerStateData}
                        onSave={(value: IUpdateTicket) => saveTicket(value)}
                        onCloseModal={() => setEditShow(false)}
                        validationMessages={validationMessages}
                    />
                )}
                {(isLoading || isContainerLoading) && <DbiLoader />}
                <div className={classes.view_wrapper}>
                    <div className={classes.tab_wrapper}>
                        <NavLink
                            end
                            to={`/tickets/${id}`}
                            className={({ isActive }) => clsx(classes.tab, { [classes.active_tab]: isActive })}
                        >
                            {t("tickets.overview")}
                        </NavLink>
                        <NavLink
                            to={`/tickets/${id}/status-logs`}
                            className={({ isActive }) => clsx(classes.tab, { [classes.active_tab]: isActive })}
                        >
                            {t("tickets.logs")}
                        </NavLink>
                        <NavLink
                            to={`/tickets/${id}/ticket-logs`}
                            className={({ isActive }) => clsx(classes.tab, { [classes.active_tab]: isActive })}
                        >
                            {t("tickets.history")}
                        </NavLink>
                    </div>
                    <Outlet context={{ ticketStateData, containerStateData, cardsStateData }} />
                </div>

                {/* MODALS */}
                <Modal
                    title={t("ticketDetail.changeStatus")}
                    subTitle={t("ticketDetail.changeStatusSubtitle")}
                    onClose={() => {
                        setStatusShow(false);
                        setStatusMessage("");
                        setValidationMessages(undefined);
                    }}
                    show={showStatus}
                    onSave={() => saveStatus()}
                    confirmText={t("ticketDetail.statusModal.confirm")}
                    cancelText={t("ticketDetail.statusModal.cancel")}
                >
                    <form className={classes.input_wrapper}>
                        <div className={classes.dbi_input}>
                            <DbiDropdown
                                label={t("ticket.table.status")}
                                value={ticketStateData?.status ?? status}
                                options={statusOptions}
                                onChange={(value) => setStatus(value)}
                            />
                        </div>
                        <div className={classes.dbi_input}>
                            <DbiTextArea
                                label={t("ticket.log.reason")}
                                value={statusMessage}
                                className={classes.dbi_input}
                                onChange={(value) => setStatusMessage(value.target.value)}
                                validationMessage={validationMessages?.reason}
                            />
                        </div>
                    </form>
                </Modal>

                <Modal
                    title={t("ticketDetail.deleteModal.title")}
                    onClose={() => setDeleteShow(false)}
                    show={showDelete}
                    onSave={() => handleDelete()}
                    confirmText={t("ticketDetail.deleteModal.confirm")}
                    cancelText={t("ticketDetail.deleteModal.cancel")}
                >
                    <p className={classes.confirm}>{t("ticketDetail.deleteModal.subtitle")}</p>
                </Modal>
            </>
        </BaseLayout>
    );
};

export default TicketOverview;
