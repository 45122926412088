import { configureStore } from "@reduxjs/toolkit";
import authReducers from "./slices/AuthSlice";
import notificationsReducers from "./slices/NotificationsSlice";
import modalReducers from "./slices/ModalSlice";

const store = configureStore({
    reducer: {
        auth: authReducers,
        notifications: notificationsReducers,
        modals: modalReducers,
    },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
