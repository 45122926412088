import classes from "./InfoBlocks.module.scss";

type Props = {
    blocks: () => React.ReactElement;
};
const InfoBlocks = (props: Props) => {
    const { blocks } = props;

    return <div className={classes.blocks}>{blocks && blocks()}</div>;
};

export default InfoBlocks;
