import AddressBox from "components/shared/Card/variants/AddressCard";
import Anchor from "components/shared/Anchor";
import BoxLinker from "components/shared/BoxLinker";
import ContainerCard from "components/shared/Card/variants/ContainerCard";
import DbiButton from "components/shared/DbiButton";

import classes from "./ContainerBox.module.css";
import { IWheelieBin, ICard, IAddressViewModel } from "types";
import { useIcbisUrl } from "hooks";
import { useTranslation } from "react-i18next";
import CardCard from "../Card/variants/CardCard/CardCard";

type Props = {
    address: IAddressViewModel;
    containers?: IWheelieBin[];
    activeWheelieBin?: number;
    cards?: ICard[];
};

export const ContainerBox = (props: Props) => {
    const { address, containers, activeWheelieBin, cards } = props;
    const { t } = useTranslation();

    const externalAddressUrl = useIcbisUrl(`/adressen/${address?.addressId}`, address?.municipality.id);

    return (
        <div className={classes.columnWrapper}>
            <AddressBox address={address}>
                <div className={classes.flexColumn}>
                    <Anchor href={externalAddressUrl} target={"_blank"} rel={"no-referrer"}>
                        <DbiButton variant={"primary-outline"} label={t("ticketDetail.linkToAddress")} />
                    </Anchor>
                </div>
            </AddressBox>
            <BoxLinker
                title={
                    containers?.length !== 0 ? t("ticketDetail.containers.title") : t("ticketDetail.containers.none")
                }
            >
                {containers?.map((bin, i) => (
                    <ContainerCard
                        bin={bin}
                        key={i}
                        active={bin.wheelieBinId === activeWheelieBin ? "active" : "in_active"}
                    />
                ))}
            </BoxLinker>
            <BoxLinker title={cards?.length !== 0 ? t("ticketDetail.cards.title") : t("ticketDetail.cards.none")}>
                {cards?.map((card, i) => (
                    <CardCard card={card} key={i} />
                ))}
            </BoxLinker>
        </div>
    );
};
