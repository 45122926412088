import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

// components
import Root from "./components/shared/Root";

// styles
import "./styles/styles.scss";

    const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
    root.render(
        <React.StrictMode>
            <Root />
        </React.StrictMode>
    );

    reportWebVitals();
// }
