import { useTranslation } from "react-i18next";
import { MRT_ColumnDef } from "material-react-table";
import { useCallback, useMemo, useState } from "react";

import { formatDateTime } from "helpers";
import { ITicketRemark } from "types/Ticket";
import { useTicketResource } from "api/resources";

import Modal from "components/shared/Modal";
import DbiButton from "components/shared/DbiButton";
import { DbiTable } from "components/shared/DbiTable";
import DbiTextArea from "components/shared/DbiTextArea";

import classes from "./TicketRemarks.module.css";
import { GET_TICKET } from "const/queryNames";
import { client } from "components/shared/Root/Root";
type Props = {
    ticketId: number;
    remarks: ITicketRemark[];
};

const TicketRemarks = (props: Props) => {
    const { ticketId, remarks } = props;
    const { t } = useTranslation();
    const { postTicketRemark } = useTicketResource();

    const [showModal, setShowModal] = useState(false);
    const [newComment, setNewComment] = useState("");

    const columns = useMemo<MRT_ColumnDef<ITicketRemark>[]>(
        () => [
            {
                header: t("ticketDetail.remarks.table.createdOn") as string,
                accessorKey: "createdOn",
                Cell: ({ renderedCellValue }) => formatDateTime(renderedCellValue as string),
            },
            {
                header: t("ticketDetail.remarks.table.comments") as string,
                accessorKey: "comments",
            },
            {
                header: t("ticketDetail.remarks.table.user") as string,
                accessorKey: "user",
            },
        ],
        [t]
    );

    const onSave = useCallback(async () => {
        if (ticketId == null) {
            return;
        }

        setShowModal(false);
        await postTicketRemark({
            id: ticketId,
            comments: newComment,
        });

        await client.invalidateQueries({ queryKey: [GET_TICKET, Number(ticketId)] });

        setNewComment("");
    }, [newComment, postTicketRemark, ticketId]);

    return (
        <>
            <div className={classes.remark__header}>
                <h4 className={classes.title}>{t("ticketDetail.remarks")}</h4>
                <DbiButton label={t("table.createNewEntry")} onClick={() => setShowModal(true)} />
            </div>

            <DbiTable columns={columns} data={remarks} contentName={t("table.remarks")} />

            <Modal
                cancelText={t("ticketDetail.newRemarkModal.cancel")}
                confirmText={t("ticketDetail.newRemarkModal.confirm")}
                onClose={() => setShowModal(false)}
                onSave={onSave}
                show={showModal}
                title={t("ticketDetail.newRemarkModal")}
            >
                <DbiTextArea
                    label={t("ticketDetail.newRemarkModal.remark")}
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                />
            </Modal>
        </>
    );
};
export default TicketRemarks;
