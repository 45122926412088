import React, { ChangeEvent, ComponentProps, Ref } from "react";
import clsx from "clsx";
import { debounce } from "helpers/debounce";

type Props = {
    label?: string;
    direction?: "row" | "column";
    disabled?: boolean;
    textarea?: boolean;
    readOnly?: boolean;
    hasDebounce?: boolean;
    searchIcon?: boolean;
    validationMessage?: string[];
    inputRef?: Ref<HTMLInputElement>;
} & ComponentProps<"input">;

const Input: React.FC<Props> = (props) => {
    const {
        label,
        direction,
        className,
        disabled,
        textarea,
        hasDebounce = false,
        onChange,
        validationMessage,
        searchIcon = false,
        inputRef,
        ...rest
    } = props;

    const handleChange = hasDebounce
        ? debounce((args: any) => {
              const event = args as ChangeEvent<HTMLInputElement>;
              onChange && onChange(event);
          })
        : onChange;

    return (
        <fieldset className={clsx("dbi-input-field", className, { "dbi-input-field--column": direction === "column" })}>
            {label && (
                <label className="dbi-input-field__label" data-required={rest.required}>
                    {label}
                </label>
            )}
            {searchIcon ? <i className={"icons8-search search"}></i> : null}
            <div className="dbi-input-field__content">
                <input
                    ref={inputRef}
                    className={clsx(
                        "dbi-input-field__input",
                        { disabled: disabled === true },
                        { "dbi-input-field__input--search": searchIcon },
                        { "dbi-input-field__input--column": direction === "column" }
                    )}
                    onChange={handleChange}
                    {...rest}
                />
                {validationMessage ? <div className="dbi_validation_message">{validationMessage}</div> : <></>}
            </div>
        </fieldset>
    );
};

export default React.forwardRef<HTMLInputElement, Props>((props, ref) => <Input {...props} inputRef={ref} />);
