import useClient from "api/useClient";

import {
    ICreateTicket,
    ICreateTicketRemark,
    ITicket,
    ITicketDetail,
    ITicketInquiry,
    ITicketRemark,
    ITicketSubject,
    IUpdateTicket,
    IWheelieBin,
    ITableQuery,
    ITableViewModel,
    IAddressDetailViewModel,
    IAddressViewModel,
    ICard,
    IBringLocation,
} from "types";

export const useTicketResource = () => {
    const client = useClient();

    const getTickets = (params: ITableQuery) => {
        return client.get<ITableViewModel<ITicket>>("ticket", {
            params: params,
        });
    };

    const getAddresses = (params: ITableQuery) => {
        return client.get<ITableViewModel<IAddressViewModel>>("ticket/addresses", {
            params: params,
        });
    };

    const getContainersForAddresses = (params: ITableQuery, addressId: number) => {
        return client.get<ITableViewModel<IWheelieBin>>("ticket/wheeliebins", {
            params: { ...params, addressId },
        });
    };

    const getTicketsForAddress = (params: ITableQuery, addressId: number) => {
        return client.get<ITableViewModel<ITicket>>(`ticket/byAddress`, {
            params: { ...params, addressId },
        });
    };

    const getCardsForAddress = (params: ITableQuery, addressId: number) => {
        return client.get<ITableViewModel<ICard>>(`ticket/cards`, {
            params: { ...params, addressId },
        });
    };

    const getUcContaintersForAddress = (params: ITableQuery, addressId: number) => {
        return client.get<ITableViewModel<IBringLocation>>(`ticket/bringlocations`, {
            params: { ...params, addressId },
        });
    };

    const getTicketDetail = (id: number) => {
        return client.get<ITicketDetail>(`ticket/${id}`);
    };

    const getTicketInquiryOptions = (params: ITableQuery, municipality: number) => {
        return client.get<ITableViewModel<ITicketInquiry>>(`ticket/inquiries`, {
            params: { ...params, municipality },
        });
    };

    const getTicketSubjects = (params: ITableQuery, municipality: number) => {
        return client.get<ITableViewModel<ITicketSubject>>(`ticket/subjects`, {
            params: { ...params, municipality },
        });
    };

    const saveTicketStatus = (id: number, status: number, reason: string) => {
        return client.put(`ticket/setStatus`, {
            id,
            status,
            reason,
        });
    };

    const getAddressDetail = (id: number) => {
        return client.get<IAddressDetailViewModel>(`ticket/addresses/${id}`);
    };

    const createTicket = (ticket: ICreateTicket) => {
        return client.post<number>("ticket", ticket);
    };

    const updateTicket = (ticket: IUpdateTicket) => {
        return client.put("ticket", ticket);
    };

    const deleteTicket = (id: number) => {
        return client.delete(`ticket/${id}`);
    };

    const postTicketRemark = (dto: ICreateTicketRemark) => {
        return client.post<ITicketRemark>("ticket/remarks", dto);
    };

    return {
        getTickets,
        getAddresses,
        getTicketsForAddress,
        getTicketDetail,
        saveTicketStatus,
        getAddressDetail,
        getContainersForAddresses,
        getTicketInquiryOptions,
        createTicket,
        updateTicket,
        deleteTicket,
        postTicketRemark,
        getTicketSubjects,
        getCardsForAddress,
        getUcContaintersForAddress,
    };
};
