import clsx from "clsx";
import { useOnClickOutside } from "usehooks-ts";
import { useCallback, useEffect, useRef } from "react";
import { useState } from "react";
import { CSSTransition } from "react-transition-group";

import classes from "./DbiDropdown.module.scss";
import { useTranslation } from "react-i18next";
import { Option } from "helpers/selectOptions";

type Props = {
    label?: string;
    options?: Value[];
    value?: number | number[] | null;
    onChange: (value: number) => void;
    canClear?: boolean;
    idKey?: string;
    usePlaceHolder?: Value;
    translate?: boolean;
    validationMessage?: string[];
    customOptionRow?: (option: any, handleChange: (selected: Option) => void) => React.ReactElement;
    customOptions?: any[];
    type?: "search";
    disabled?: boolean;
};

type Value = {
    label: string;
    value: number;
};

const DbiDropdown = (props: Props) => {
    const {
        value,
        options,
        idKey = "value",
        onChange,
        label,
        canClear,
        usePlaceHolder,
        translate,
        validationMessage,
        customOptionRow = undefined,
        customOptions = undefined,
        type = undefined,
        disabled = false,
    } = props;
    const ref = useRef(null);
    const nodeRef = useRef(null);
    const { t } = useTranslation();

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selected, setSelected] = useState<Value>();
    const [hasOptions, setHasOptions] = useState(false);

    const handleClickOutside = () => {
        setDropdownOpen(false);
    };

    useEffect(() => {
        if (options && options.length > 0) {
            setHasOptions(true);
        }

        if (customOptions && customOptions.length > 0) {
            setHasOptions(true);
        }
    }, [options, customOptions]);

    useEffect(() => {
        if (usePlaceHolder === undefined || !hasOptions) {
            return;
        }
        if (options) {
            const index = options.findIndex((option) => option.value === usePlaceHolder.value);
            if (index === -1) {
                options.unshift(usePlaceHolder);
            }
        }
        if (value == null) setSelected(usePlaceHolder);
    }, [options, usePlaceHolder, value, hasOptions]);

    useEffect(() => {
        if (value == null) {
            return;
        }
        if (!options) return;
        const current = options?.find((item: any) => item[idKey] === value);
        if (current) {
            setSelected(current);
        }
    }, [value, options, idKey, usePlaceHolder]);

    useOnClickOutside(ref, handleClickOutside);

    const handleChange = useCallback(
        (selected: Option) => {
            setDropdownOpen(false);
            setSelected(selected);
            onChange(selected.value);
        },
        [onChange]
    );

    return (
        <>
            <fieldset className="dbi-input-field">
                {label && <label className="dbi-input-field__label">{label}</label>}
                <div className="dbi-input-field__content">
                    <div
                        className={clsx(
                            "dbi-input-field__input",
                            { disabled: disabled === true },
                            { "dbi-input-field__dropdown--search": type === "search" },
                            { "dbi-input-field__dropdown--disabled": !hasOptions }
                        )}
                        ref={ref}
                    >
                        <div className={classes.dropdown_wrapper}>
                            <div
                                className={classes.dropdown_value}
                                onClick={() => {
                                    setDropdownOpen(!dropdownOpen);
                                }}
                            >
                                {selected && <span>{selected.label}</span>}
                                {!hasOptions && <span>{t("dropdown.noItems")}</span>}
                                {hasOptions && (
                                    <i
                                        className={clsx(classes.input_icon, {
                                            "icons8-sort-up": dropdownOpen,
                                            "icons8-sort-down": !dropdownOpen,
                                        })}
                                    ></i>
                                )}
                            </div>
                            {canClear === true ? (
                                <i
                                    className={clsx(classes.input_icon, classes.clear, "icons8-delete")}
                                    onClick={() => {
                                        onChange(0);
                                    }}
                                ></i>
                            ) : (
                                <></>
                            )}
                        </div>
                        <CSSTransition
                            nodeRef={nodeRef}
                            unmountOnExit
                            in={dropdownOpen}
                            timeout={0}
                            className={classes.dropdown_content}
                        >
                            <div>
                                {customOptionRow &&
                                    customOptions &&
                                    customOptions.map((option) => customOptionRow(option, handleChange))}

                                {!customOptionRow &&
                                    options &&
                                    options.map((option, index) => (
                                        <div
                                            className={classes.option}
                                            onClick={() => handleChange(option)}
                                            key={index}
                                        >
                                            {translate ? t(`actions.${option.label}`) : option.label}
                                        </div>
                                    ))}
                            </div>
                        </CSSTransition>
                    </div>
                    {validationMessage ? <div className="dbi_validation_message">{validationMessage}</div> : <></>}
                </div>
            </fieldset>
        </>
    );
};
export default DbiDropdown;
