import { useMemo } from "react";
import config from "../api/config";
import { useAppSelector } from "./useStore";

/**
 * Generates a URL which signs in to ICBIS using the current sign-in token, redirecting to `redirectTo` and
 * selecting the correct municipality (if available to the user). The host is retrieved from build-time env variables.
 * @param redirectTo The redirect URL. Should not include the host. Will be prefixed with a forward slash (`/`) if omitted.
 * @param municipalityId The target municipality ID.
 * @returns {string} The redirect path to the target host through the ICBIS SSO provider route (`/account/sso`).
 * @example
 * const url = useIcbisUrl(redirectTo, municipalityId);
 * // returns ${icbis_host}/account/sso?redirectTo=${redirectTo}&municipalityId=${municipalityId}&token=${accessToken}
 */
export const useIcbisUrl = (redirectTo: string, municipalityId: number) => {
    const accessToken = useAppSelector((state) => state.auth.accessToken);
    if (redirectTo[0] !== "/") {
        redirectTo = `/${redirectTo}`;
    }

    return useMemo(
        () =>
            `${config.icbisUrl}/account/sso?redirectTo=${redirectTo}&token=${accessToken}&municipalityId=${municipalityId}`,
        [accessToken, municipalityId, redirectTo]
    );
};
