import { PropsWithChildren, useMemo } from "react";
import clsx from "clsx";

import classes from "./Card.module.css";
import Anchor from "../Anchor";

type Props = {
    className?: string;
    href?: string;
};

const Card = ({ children, href, className }: PropsWithChildren<Props>) => {
    const interactive = useMemo(() => href != null && href !== "", [href]);

    return interactive ? (
        <Anchor href={href} className={clsx(classes.card, className, { [classes.card__interactive]: interactive })}>
            <div className={clsx(classes.flexRow)}>{children}</div>
        </Anchor>
    ) : (
        <div className={clsx(classes.card, className, { [classes.card__interactive]: interactive })}>
            <div className={clsx(classes.flexRow)}>{children}</div>
        </div>
    );
};

export default Card;
