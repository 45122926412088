import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type INotification = {
    id: number;
    content: string;
    severity: "message" | "warning" | "error";
};
export type INewNotification = Omit<INotification, "id">;

type State = {
    notifications: INotification[];
    index: number;
};

const initialState: State = {
    notifications: [],
    index: 0,
};

const slice = createSlice({
    name: "Notifications",
    initialState,
    reducers: {
        addNotification: (state, action: PayloadAction<INewNotification>) => {
            state.notifications.push({
                id: ++state.index,
                ...action.payload,
            });
        },
        dismissNotification: (state, action: PayloadAction<number>) => {
            const id = action.payload;
            const index = state.notifications.findIndex((a) => a.id === id);
            if (index === -1) {
                return;
            }
            state.notifications.splice(index, 1);
        },
    },
});

export const { addNotification, dismissNotification } = slice.actions;
export default slice.reducer;
