import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";
import { IDecodedToken } from "../../types";
import Cookies from "js-cookie";

type State = {
    accessToken: string;
    login: boolean;
    token?: IDecodedToken;
    rememberMe: boolean;
    rememberDays?: number;
    // A specific token used to prevent the user from being reprompted for a 2FA code
    rememberTwoFactorToken?: string;
    canRemember: boolean;
};

const initialState: State = {
    rememberMe: Cookies.get("remember-me-dbi") === "true",
    accessToken: Cookies.get("jwt-dbi") ?? "",
    login: false,
    canRemember: false,
    rememberTwoFactorToken: Cookies.get("remember-2fa-dbi"),
};

export const AuthSlice = createSlice({
    name: "Auth",
    initialState,
    reducers: {
        setAccessToken: (
            state,
            action: PayloadAction<{ accessToken: string; rememberTwoFactorToken?: string; remember?: boolean }>
        ) => {
            const { accessToken, rememberTwoFactorToken, remember } = action.payload;
            state.accessToken = accessToken;
            state.rememberTwoFactorToken = rememberTwoFactorToken;

            if (remember != null) {
                state.rememberMe = remember;
                Cookies.set("remember-me-dbi", "true", { path: "/" });
            }

            state.token = jwtDecode<IDecodedToken>(state.accessToken);
            Cookies.set("jwt-dbi", state.accessToken, { path: "/" });
            if (state.rememberTwoFactorToken != null)
                Cookies.set("remember-2fa-dbi", state.rememberTwoFactorToken, { path: "/" });

            state.login = true;
        },
        set2FAToken: (state, action: PayloadAction<{ accessToken: string; rememberDays?: number }>) => {
            const { accessToken, rememberDays } = action.payload;
            state.accessToken = accessToken;
            state.token = jwtDecode<IDecodedToken>(state.accessToken);
            state.rememberDays = rememberDays;
            state.canRemember = state.rememberDays != null;
        },
        logout: (state) => {
            state.login = false;
            state.token = undefined;
            state.accessToken = "";
            Cookies.remove("jwt-dbi", { path: "/" });
            if (!state.rememberMe) {
                state.rememberTwoFactorToken = undefined;
                Cookies.remove("remember-2fa-dbi", { path: "/" });
            }
        },
    },
});

// Action creators are generated for each case reducer function
export const { logout, setAccessToken, set2FAToken } = AuthSlice.actions;
export default AuthSlice.reducer;
