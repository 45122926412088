import { PropsWithChildren } from "react";

import { IAddressDetailViewModel, IAddressViewModel } from "types/Address";
import Card from "components/shared/Card";
import DbiLoader from "components/shared/DbiLoader";

import classes from "./AddressCard.module.css";
import { useTranslation } from "react-i18next";
import { getFullHouseNumber } from "helpers";

type Props = {
    address?: IAddressViewModel | IAddressDetailViewModel;
    displayMunicipality?: true | undefined;
};

const AddressCard = function ({ address, children, displayMunicipality }: PropsWithChildren<Props>) {
    const { t } = useTranslation();

    return (
        <Card className={classes.addressBox}>
            {address == null ? (
                <DbiLoader />
            ) : (
                <>
                    <div className={classes.flexColumn}>
                        <h4 className={classes.address}>
                            {address?.street?.name} {getFullHouseNumber(address)}
                        </h4>
                        <div
                            className={classes.postalCodeCity}
                        >{`${address?.postalCode} ${address?.street?.city.name}`}</div>
                        {displayMunicipality ? (
                            address.municipality != null ? (
                                <>
                                    <p className={classes.organization}>{address.municipality.organizationName}</p>
                                    <p className={classes.municipality}>{address.municipality.name}</p>
                                </>
                            ) : (
                                <p className={classes.municipality}>{t("address.unknownMunicipality")}</p>
                            )
                        ) : null}
                    </div>
                    {children}
                </>
            )}
        </Card>
    );
};

export { AddressCard as default };
