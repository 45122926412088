import { IAddressViewModel } from "types/Address";

export const getFullAddress = (address: IAddressViewModel) => {
    if (!address) return "";

    const fullHouseNumber = getFullHouseNumber(address);
    const zipCode = address.postalCode;

    const street = address.street.name;
    const city = address.street.city.name;

    return `${street} ${fullHouseNumber}, ${zipCode} ${city}`;
};

export const getFullHouseNumber = (address: IAddressViewModel) => {
    if (!address) return "";
    const houseNumber = address.houseNumber;
    const houseNumberAddition = address.houseNumberAddition;
    const letter = address.houseLetter;

    return `${houseNumber} ${letter}${houseNumberAddition}`;
};
