import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { useQuery } from "@tanstack/react-query";

import { IReport } from "types";
import DbiButton from "components/shared/DbiButton";
import { useContactCenterReportResource } from "api/resources";

import classes from "../Modal.module.css";
import exportClasses from "./ExportModal.module.css";

type Props = {
    onClose: () => void;
};

export const ExportModal = (props: Props) => {
    const { onClose } = props;
    const { t } = useTranslation();
    const { getReports, downloadCSVReport } = useContactCenterReportResource();

    const filterReportList = ["TicketReport", "OpenTicketReport"];

    const downloadReport = useCallback(
        (report: IReport) => {
            downloadCSVReport(report);
        },
        [downloadCSVReport]
    );

    // Queries
    const reportQuery = useQuery({ queryKey: ["reportsQuery"], queryFn: getReports });

    return (
        <div className={classes.content}>
            <div className={classes.header}>
                <div className={classes.title}>{t("ticketExport.title")}</div>
                <div className={classes.subtitle}>{t("ticketExport.subTitle")}</div>
                <i className={clsx("icons8-delete", classes.close)} onClick={() => onClose()} />
            </div>
            <div className={classes.body}>
                {reportQuery.data &&
                    reportQuery.data.data
                        .filter((report: IReport) => !filterReportList.includes(report.name))
                        .map((report: IReport, idx: number) => (
                            <div className={exportClasses["files__row"]} key={idx}>
                                <h6 className={exportClasses["files__title"]}>{report.title}</h6>
                                <DbiButton
                                    label={t("ticketExport.download")}
                                    className={exportClasses["files__button"]}
                                    onClick={() => downloadReport(report)}
                                />
                            </div>
                        ))}
            </div>
        </div>
    );
};
