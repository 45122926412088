import { useCallback, useState } from "react";
import { useDebounce } from "usehooks-ts";

import { ITableQuery } from "types/Client";
import { ColumnSort } from "@tanstack/react-table";

/**
 * Manages and debounces filter search terms.
 * @param initialState Initial state to load the table query with
 */
export const useQueryParams = (initialState?: ITableQuery) => {
    const [queryParams, setQueryParams] = useState<ITableQuery>(initialState ?? { searchTerms: {} });
    const debouncedQueryParams = useDebounce(queryParams, 100);

    const setSortedBy = useCallback((sorting: ColumnSort[]) => {
        setQueryParams((prevState) => {
            if (sorting.length === 0) {
                return { ...prevState, sortedBy: undefined, direction: undefined };
            }
            const { desc, id } = sorting[0];
            return { ...prevState, sortedBy: id, direction: desc ? "desc" : "asc" };
        });
    }, []);

    const setSearchTerms = useCallback((key: string, value: string | undefined) => {
        setQueryParams((prevState) => {
            const terms = prevState.searchTerms ?? {};
            if (value == null || value === "") {
                delete terms[key];
            } else {
                terms[key] = value;
            }

            return { ...prevState, searchTerms: terms };
        });
    }, []);

    const setSearchTerm = useCallback(
        (value: string | undefined) =>
            setQueryParams((prevState) => ({
                ...prevState,
                searchTerm: value,
            })),
        []
    );

    return {
        queryParams: debouncedQueryParams,
        setSearchTerms,
        setSearchTerm,
        setSortedBy,
    };
};
