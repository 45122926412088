import clsx from "clsx";
import classes from "./Chip.module.css";
import { PropsWithChildren } from "react";

type Props = {
    variant?: "success" | "warning";
};

const Chip = ({ children, variant }: PropsWithChildren<Props>) => {
    return <span className={clsx(classes.chip, classes[`chip__${variant}`])}>{children}</span>;
};

export default Chip;
