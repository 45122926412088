import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import classes from "./modal.module.scss";
import DbiButton from "../DbiButton";
import clsx from "clsx";

export interface Props {
    cancelText?: string;
    children: React.ReactChild;
    confirmText?: string;
    onClose: Function;
    onSave?: Function;
    show: boolean;
    subTitle?: string;
    title: string;
}

const Modal: React.FC<Props> = (props: Props) => {
    useEffect(() => {
        const closeOnEscapeKeyDown = (e: KeyboardEvent) => {
            if (e.key === "Escape") {
                props.onClose();
            }
        };
        document.body.addEventListener("keydown", closeOnEscapeKeyDown);
        return () => {
            document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const nodeRef = React.useRef(null);
    const rootElement = document.getElementById("root")!;

    return ReactDOM.createPortal(
        <CSSTransition nodeRef={nodeRef} unmountOnExit in={props.show} timeout={0}>
            <div
                className={classes.modal}
                ref={nodeRef}
                onClick={() => {
                    props.onClose && props.onClose();
                }}
            >
                <div className={classes.content} onClick={(e) => e.stopPropagation()}>
                    <div className={classes.header}>
                        <div className={classes.title}>{props.title}</div>
                        {props.subTitle ? <div className={classes.subtitle}>{props.subTitle}</div> : <></>}
                        <i
                            className={clsx("icons8-delete", classes.close)}
                            onClick={() => {
                                props.onClose && props.onClose();
                            }}
                        />
                    </div>
                    <div className={classes.body}>{props.children}</div>
                    {props.cancelText && props.confirmText && (
                        <div className={classes.footer}>
                            {props.cancelText && (
                                <DbiButton
                                    variant={"red-outline"}
                                    onClick={() => {
                                        props.onClose && props.onClose();
                                    }}
                                    label={props.cancelText ? props.cancelText : ""}
                                />
                            )}
                            {props.confirmText && (
                                <DbiButton
                                    variant={"primary"}
                                    onClick={() => {
                                        props.onSave && props.onSave();
                                    }}
                                    label={props.confirmText ? props.confirmText : ""}
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </CSSTransition>,
        rootElement
    );
};

export default Modal;
