import { IAccessTokenTwoFactorRequest, ILogin, ILoginResult } from "types";
import { AxiosResponse } from "axios";
import useClient from "api/useClient";

type AccountResource = {
    generateAccessToken: (loginData: ILogin) => Promise<AxiosResponse<ILoginResult>>;
    generateTwoFactorAccessToken: (
        accessToken: string,
        req: IAccessTokenTwoFactorRequest
    ) => Promise<AxiosResponse<ILoginResult>>;
    generateTwoFactorAccessTokenUsingRecoveryToken: (
        accessToken: string,
        req: IAccessTokenTwoFactorRequest
    ) => Promise<AxiosResponse<ILoginResult>>;
};

export const useAccountResource = (): AccountResource => {
    const client = useClient();

    const generateAccessToken = (loginData: ILogin) => {
        return client.post<ILoginResult>("contactCenterAccount/generateAccessToken", loginData);
    };

    const generateTwoFactorAccessToken = (accessToken: string, req: IAccessTokenTwoFactorRequest) => {
        return client.post<ILoginResult>(
            "contactCenterAccount/generateTwoFactorAccessToken",
            {
                ...req,
                timezoneOffset: new Date().getTimezoneOffset() / 60,
            },
            {
                headers: {
                    Authorization: `bearer ${accessToken}`,
                },
            }
        );
    };

    const generateTwoFactorAccessTokenUsingRecoveryToken = (accessToken: string, req: IAccessTokenTwoFactorRequest) => {
        return client.post<ILoginResult>(
            "contactCenterAccount/generateTwoFactorAccessTokenUsingRecoveryToken",
            {
                ...req,
                timezoneOffset: new Date().getTimezoneOffset() / 60,
            },
            {
                headers: {
                    Authorization: `bearer ${accessToken}`,
                },
            }
        );
    };

    return {
        generateAccessToken,
        generateTwoFactorAccessToken,
        generateTwoFactorAccessTokenUsingRecoveryToken,
    };
};
