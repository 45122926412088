import clsx from "clsx";
import { formatDate } from "helpers";
import { useTranslation } from "react-i18next";
import classes from "../InfoBlocks.module.scss";

type Props = {
    customContent?: string;
};

export const DateBlock = (props: Props) => {
    const { t } = useTranslation();
    return (
        <div className={classes.block}>
            <i className={clsx("icons8-system-task", classes.icon_big)} />
            <i className={clsx("icons8-system-task", classes.icon_small)} />
            <div className={classes.title}>{formatDate(new Date())}</div>
            <div className={classes.content}>{t("tickets.openTickets.date")}</div>
        </div>
    );
};
