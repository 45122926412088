import moment from "moment";

export enum Format {
    /**
     * DEFAULT_DATE_FORMAT: maandag 30 mei 2022
     */
    DEFAULT_DATE_FORMAT = "dddd LL",

    /**
     * DEFAULT_TIME_FORMAT: 11:42:55
     */
    DEFAULT_TIME_FORMAT = "HH:mm:ss",

    /**
     * DATE_LONGTIME_FORMAT: 30 mei 2022 11:42:55
     */
    DATE_LONGTIME_FORMAT = "LL HH:mm:ss",

    /**
     * SHORT_DATE_FORMAT: 30 mei 2022
     */
    SHORT_DATE_FORMAT = "LL",

    /**
     * SHORT_TIME_FORMAT: 11:42:55
     */
    SHORT_TIME_FORMAT = "HH:mm:ss",

    /**
     * DATE_TIME_FORMAT: maandag 30 mei 2022 11:42
     */
    DATE_TIME_FORMAT = "LLLL",

    /**
     * SHORT_DATE_TIME_FORMAT: 30-05-2022 11:42
     */
    SHORT_DATE_TIME_FORMAT = "DD-MM-YYYY HH:mm",

    /**
     * SHORT_DATE: 30-05-2022
     */
    SHORT_DATE = "DD-MM-YYYY",
}

export const getMomentLocal = (d: moment.MomentInput) => moment.utc(d).local();

export const formatDate = (d: moment.MomentInput, s: Format = Format.DEFAULT_DATE_FORMAT) => {
    if (d) {
        return getMomentLocal(d).format(s);
    }
};

export const formatDateShort = (d: moment.MomentInput) => {
    if (d) {
        return getMomentLocal(d).format(Format.SHORT_DATE_FORMAT);
    }
};

export const formatTimeShort = (d: moment.MomentInput) => {
    if (d) {
        return getMomentLocal(d).format(Format.SHORT_TIME_FORMAT);
    }
};

export const formatDateTime = (d: moment.MomentInput) => {
    if (d) {
        return getMomentLocal(d).format(Format.DATE_TIME_FORMAT);
    }
};

export const formatDateTimeShort = (d: moment.MomentInput) => {
    if (d) {
        return getMomentLocal(d).format(Format.SHORT_DATE_TIME_FORMAT);
    }
};
