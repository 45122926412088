import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import { ITicketDetail, IUpdateTicket } from "types/Ticket";
import { IWheelieBin } from "types/WheelieBin";
// import { actionsList } from "const/actions";
// import { mapDataToSelectOptions } from "helpers/selectOptions";

// import DbiDropdown from "components/shared/DbiDropdown";
import Modal from "components/shared/Modal";
import DbiTextArea from "components/shared/DbiTextArea";
import DbiInput from "components/shared/DbiInput";

import { IValidationMessages } from "types/ValidationMessages";

import classes from "./EditTicketModal.module.scss";

interface Props {
    ticket: ITicketDetail;
    containers?: IWheelieBin[];
    onSave: Function;
    show: boolean;
    onCloseModal: Function;
    validationMessages?: IValidationMessages;
}

const EditTicket = (props: Props) => {
    const { ticket, onSave, onCloseModal, show, validationMessages } = props;
    const { t } = useTranslation();
    // const { getTicketInquiryOptions } = useTicketResource();
    // const [inquireOptions, setInquireOptions] = useState<ITicketInquiry[]>([]);
    const [showEdit, setEditShow] = useState(show);

    const [updatedTicket, setUpdatedTicket] = useState<IUpdateTicket>({
        id: ticket.id,
        addressId: ticket.address?.addressId,
        // objectId: ticket.objectId ?? 0,
        // subjectId: ticket.subjectId,
        // actionIds: ticket.actions ?? [],
        actionRemark: ticket.actionRemark ?? undefined,
        description: ticket.description,
        emailAddress: ticket.contactEmailAddress ?? "",
        phoneNumber: ticket.contactPhoneNumber ?? "",
        objectType: ticket.objectType ?? 0,
    });

    // const translateActionList = actionsList.map((item) => ({
    //     value: item.value,
    //     text: t(`actions.${item.text}`),
    // }));

    useEffect(() => {
        // check if object ticket has values(keys)
        if (Object.keys(ticket).length <= 0) return;

        // getTicketInquiryOptions({}, ticket.address.municipality.id).then((response) => {
        //     setInquireOptions(response.data.tableData);
        // });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ticket]);

    useEffect(() => {
        setEditShow(show);
    }, [show]);

    useEffect(() => {
        setUpdatedTicket({
            id: ticket.id,
            addressId: ticket.address?.addressId,
            // objectId: ticket.objectId ?? 0,
            // subjectId: ticket.subjectId,
            // actionIds: ticket.actions ?? [],
            actionRemark: ticket.actionRemark ?? "",
            description: ticket.description,
            emailAddress: ticket.contactEmailAddress,
            phoneNumber: ticket.contactPhoneNumber,
            objectType: ticket.objectType ?? 0,
            subjectItemIds: ticket.subjectItems.map((i) => i.id),
        });
    }, [ticket]);

    function closeModal() {
        setEditShow(false);
        onCloseModal();
    }

    async function saveTicket() {
        // if (updatedTicket.action !== ITicketAction.Other) {
        //     delete updatedTicket.actionRemark;
        // } else {
        //     delete updatedTicket.wheelieBinId;
        // }

        // TODO fix method
        onSave(updatedTicket);
    }

    return (
        <Modal
            title={t("ticketDetail.editTicket")}
            onSave={() => saveTicket()}
            confirmText={t("ticketDetail.statusModal.confirm")}
            cancelText={t("ticketDetail.statusModal.cancel")}
            onClose={() => closeModal()}
            show={showEdit}
        >
            <div className={classes.edit_wrapper}>
                {/* <div className={classes.br}>
                    <DbiDropdown
                        label={t("ticket.table.inquiry")}
                        value={updatedTicket.inquiryId}
                        onChange={(val) => setUpdatedTicket({ ...updatedTicket, inquiryId: val })}
                        options={mapDataToSelectOptions(inquireOptions, "id", "content")}
                        validationMessage={validationMessages?.inquiryId}
                    />
                </div>
                <div className={classes.br}>
                    <DbiDropdown
                        label={t("ticket.table.action")}
                        value={updatedTicket.action}
                        onChange={(val) => setUpdatedTicket({ ...updatedTicket, action: val })}
                        options={translateActionList}
                        validationMessage={validationMessages?.action}
                    />
                </div>
                {updatedTicket.action === ITicketAction.Other ? (
                    <div className={classes.br}>
                        <DbiTextArea
                            label={t("ticketDetail.actionRemark")}
                            value={updatedTicket.actionRemark ? updatedTicket.actionRemark : undefined}
                            onChange={(val) => setUpdatedTicket({ ...updatedTicket, actionRemark: val.target.value })}
                            validationMessage={validationMessages?.actionRemark}
                        />
                    </div>
                ) : null}
                {containers && updatedTicket.action !== ITicketAction.Other ? (
                    <div className={classes.br}>
                        <DbiDropdown
                            label={t("ticket.table.container")}
                            value={Number(updatedTicket.wheelieBinId)}
                            onChange={(val) => setUpdatedTicket({ ...updatedTicket, wheelieBinId: val })}
                            options={mapDataToSelectOptions(containers, "wheelieBinId", "name")}
                            validationMessage={validationMessages?.wheelieBinId}
                        />
                    </div>
                ) : null} */}
                <div className={classes.br}>
                    <DbiTextArea
                        label={t("ticket.table.description")}
                        value={updatedTicket.description ? updatedTicket.description : ""}
                        onChange={(val) => setUpdatedTicket({ ...updatedTicket, description: val.target.value })}
                        validationMessage={validationMessages?.description}
                    />
                </div>
                <div className={classes.br}>
                    <DbiInput
                        value={updatedTicket.emailAddress ?? ""}
                        label={t("ticketDetail.emailAddress")}
                        onChange={(val) => setUpdatedTicket({ ...updatedTicket, emailAddress: val.target.value })}
                        validationMessage={validationMessages?.emailAddress}
                    />
                </div>
                <div className={classes.br}>
                    <DbiInput
                        value={updatedTicket.phoneNumber ?? ""}
                        label={t("ticketDetail.phoneNumber")}
                        onChange={(val) => setUpdatedTicket({ ...updatedTicket, phoneNumber: val.target.value })}
                        validationMessage={validationMessages?.phoneNumber}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default EditTicket;
