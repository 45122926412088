import { DateString, WheelieBinStatus } from "types";

export interface IWheelieBin {
    address: string;
    addressId: number;
    barcode: string;
    blacklistStatus: BlacklistStatus;
    containerNumber: string;
    createdOn: Date;
    extra: boolean;
    fraction: FractionType;
    hasActiveChip: boolean;
    name: string;
    note: string;
    status: WheelieBinStatus;
    statusComment: string;
    volume: VolumeType;
    wheelieBinId: number;
    wheelieBinType: number;
}
export enum WheelieBinType {
    WheelieBin = 0,
    CollectionContainer = 1,
}

export enum BlacklistStatus {
    Excluded = 1,
    Whitelist = 2,
    Blacklist = 3,
}

export enum FractionType {
    Rest = 0,
    Gft = 1,
    Opk = 2,
    Pmd = 3,
    Sortie = 4,
    Plastic = 5,
}

export enum VolumeType {
    V140 = 0,
    V180 = 1,
    V240 = 2,
    V660 = 3,
    V770 = 4,
    V1100 = 5,
    V1300 = 6,
    V1600 = 7,
    V80 = 8,
    V120 = 9,
    V280 = 10,
}

export interface IWheelieBinChipPeriod {
    id: number;
    wheelieBinId: number;
    chip: IChip;
    isActive: boolean;
    StartTime: DateString;
    EndTime: DateString;
}

export interface IChip {
    chipId: number;
    chipNumber: string;
    isActive: boolean;
    createdOn: DateString;
}
