import { Outlet } from "react-router-dom";

import classes from "./AuthLayout.module.scss";
import { useEffect } from "react";
import i18next from "i18next";
import { ILang } from "types";
import { resources } from "locale";
import { DbiLogo } from "components/shared/DbiLogo/DbiLogo";
import { FALLBACK_LNG } from "const/locale";

const Layout = () => {
    useEffect(() => {
        getUserLanguage();
    }, []);

    const getUserLanguage = () => {
        const path = window.location.origin;
        let lang = FALLBACK_LNG;

        Object.keys(resources).forEach((resource: string) => {
            const current = resources[resource as ILang];
            if (path.includes(current.topLevelDomain)) {
                lang = resource;
            }
        });

        i18next.changeLanguage(lang).then();
    };

    return (
        <div className={classes.page}>
            <div className={classes.cover}></div>
            <div className={classes.content}>
                <div className={classes.wrap}>
                    <div className={classes.logo}>
                        <DbiLogo themeColor="dark" />
                    </div>
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export default Layout;
