import { DateString } from "types";

export interface IBringLocation {
    id?: number;
    description: string;
    code: string;
    containerCount: number;
    createdOn: DateString;
    containers: IUndergroundContainerViewModel[];
}

export interface IUndergroundContainerViewModel {
    id: number;
    serialNumber: number;
    name: string;
    fraction: UcFractionType;
}

export enum UcFractionType {
    // Restafval
    Rest = 0,

    // GFT
    Gft = 1,

    // OPK
    Opk = 2,

    // PMD
    Pmd = 3,

    // Textiel
    Textile = 4,

    //Glas
    Glass = 5,

    // BLIK
    Tinplate = 6,

    // Plastic
    Plastic = 7,

    // Incontinentie
    Incontinence = 8,

    // Luier
    Diaper = 9,

    // Drank en sap pakken
    BeveragePacks = 10,

    // Sticker specifiek klant
    //SpecificCustomer = 11
}
