import { IWheelieBin, WheelieBinType, FractionType } from "types/WheelieBin";

import container_blue from "../assets/images/container_blue.png";
import container_gray from "../assets/images/container_gray.png";
import container_green from "../assets/images/container_green.png";
import container_orange from "../assets/images/container_orange.png";
import container_purple from "../assets/images/container_purple.png";

import collectionContainer_blue from "../assets/images/collectionContainer_blue.png";
import collectionContainer_gray from "../assets/images/collectionContainer_gray.png";
import collectionContainer_green from "../assets/images/collectionContainer_green.png";
import collectionContainer_orange from "../assets/images/collectionContainer_orange.png";
import collectionContainer_purple from "../assets/images/collectionContainer_purple.png";

import { WheelieBinStatus } from "types/WheelieBinStatus";

export function getWheeliebinImage(wheeliebin: IWheelieBin) {
    if (wheeliebin.wheelieBinType === WheelieBinType.WheelieBin) {
        switch (wheeliebin.fraction) {
            case FractionType.Rest:
                return container_gray;
            case FractionType.Gft:
                return container_green;
            case FractionType.Opk:
                return container_blue;
            case FractionType.Pmd:
                return container_orange;
            case FractionType.Sortie:
                return container_purple;
            case FractionType.Plastic:
                return container_orange;
            default:
                throw new Error(`Invalid fraction: ${wheeliebin.fraction}`);
        }
    } else {
        switch (wheeliebin.fraction) {
            case FractionType.Rest:
                return collectionContainer_gray;
            case FractionType.Gft:
                return collectionContainer_green;
            case FractionType.Opk:
                return collectionContainer_blue;
            case FractionType.Pmd:
                return collectionContainer_orange;
            case FractionType.Sortie:
                return collectionContainer_purple;
            case FractionType.Plastic:
                return collectionContainer_orange;
            default:
                throw new Error(`Invalid fraction: ${wheeliebin.fraction}`);
        }
    }
}

export function getContainers(wheelieBinType: WheelieBinType) {
    if (wheelieBinType === WheelieBinType.WheelieBin) {
        return "containers";
    }
    return "collectionContainers";
}

export function getStatusColor(status: WheelieBinStatus): string {
    switch (status) {
        case WheelieBinStatus.Stolen:
            return "red";
        case WheelieBinStatus.New:
        case WheelieBinStatus.TakenIn:
            return "gray";
        case WheelieBinStatus.Active:
            return "green";
        case WheelieBinStatus.Defect:
        case WheelieBinStatus.Blocked:
        case WheelieBinStatus.Destroyed:
            return "red";
        default:
            throw new Error(`Invalid wheelie bin status: ${status}`);
    }
}
