import { useTranslation } from "react-i18next";
import { FormEvent, useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { TwoFactorModal } from "components/auth/TwoFactorModal";

import { AuthService } from "services";

import Input from "components/shared/DbiInput";
import DbiButton from "components/shared/DbiButton";

import classes from "./Login.module.scss";

const Login = () => {
    const { t } = useTranslation();
    const authService = AuthService();
    const nav = useNavigate();
    const [searchParams] = useSearchParams();

    // State
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [prompt, setPrompt] = useState<string>(t("auth.loginPrompt"));
    const [showTwoFactor, setShowTwoFactor] = useState(false);
    const [validationMessages, setValidationMessages] = useState<string[]>([]);

    const onFormSubmit = useCallback(
        async (event: FormEvent) => {
            event.preventDefault();
            setLoading(true);

            const result = await authService.signIn(username, password);
            setLoading(false);
            if (result.error) {
                setError(result.error.message);
                return;
            }

            if (result.requiresTwoFactor) {
                setShowTwoFactor(true);
                return;
            }

            const redirect = searchParams.get("redirectTo");
            if (redirect == null) {
                nav("/");
            } else {
                nav(redirect, { replace: true });
            }
        },
        [authService, nav, password, searchParams, username]
    );

    const handleSubmit2faCode = useCallback(
        async (code: string, remember: boolean, isRecovery: boolean) => {
            let result;
            if (!isRecovery) {
                result = await authService.generateTwoFactorAccessToken(code, remember);
            } else {
                result = await authService.generateTwoFactorAccessTokenUsingRecoveryToken(code);
            }

            if (result.error) {
                setValidationMessages([result.error.message]);
                return;
            }
            nav("/");
        },
        [authService, nav]
    );

    const handleClose2faModal = useCallback(() => {
        setValidationMessages([]);
        setShowTwoFactor(false);
    }, []);

    useEffect(() => {
        if (searchParams.has("redirectTo")) {
            setPrompt(t("auth.loginPrompt.loginRequired"));
            return;
        }
        if (searchParams.has("resetPasswordSuccess")) {
            setPrompt(t("auth.loginPrompt.resetPasswordSuccess"));
            return;
        }
    }, [searchParams, t]);

    return (
        <div>
            <TwoFactorModal
                show={showTwoFactor}
                onSubmit2faCode={handleSubmit2faCode}
                validationMessages={validationMessages}
                onClose={handleClose2faModal}
            />
            <h1>{t("auth.login")}</h1>
            <p>{prompt}</p>
            <form className={classes.form} onSubmit={onFormSubmit}>
                <div className={classes.row}>
                    <Input
                        autoFocus
                        label={t("auth.email")}
                        autoComplete="email"
                        placeholder={t("auth.emailPlaceholder")}
                        required={true}
                        value={username}
                        onChange={(event) => setUsername(event.target.value)}
                    />
                </div>
                <div className={classes.row}>
                    <Input
                        className="password"
                        type="password"
                        label={t("auth.password")}
                        autoComplete="current-password"
                        placeholder={t("auth.passwordPlaceholder")}
                        required={true}
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                    />
                </div>
                <div className={classes.buttons}>
                    <Link className="button button--ghost" to={`/auth/forgot-password?email=${username}`}>
                        {t("auth.forgotPassword")}
                    </Link>
                    <DbiButton variant={"primary"} type="submit" label={t("auth.login")} loading={loading} />
                </div>
            </form>
            {error && (
                <div className={classes.errors}>
                    <span>{error}</span>
                </div>
            )}
        </div>
    );
};
export default Login;
