import TopBar from "components/shared/TopBar/TopBar";
import classes from "./BaseLayout.module.css";
import { Helmet } from "react-helmet-async";
import { PropsWithChildren, ReactNode, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

type Props = {
    buttons?: ReactNode;
    breadcrumb?: string;
    pageTitle?: string;
};

const BaseLayout = ({ children, buttons, breadcrumb, pageTitle }: PropsWithChildren<Props>) => {
    const [currentPath, setCurrentPath] = useState("");
    const location = useLocation();

    useEffect(() => {
        if (pageTitle) {
            setCurrentPath(pageTitle);
            return;
        }
        if (breadcrumb) {
            setCurrentPath(breadcrumb);
            return;
        }
        const locationPath = location.pathname;
        const pathName = locationPath.substring(1, locationPath.length);
        setCurrentPath(pathName);
    }, [location, breadcrumb, pageTitle]);

    return (
        <>
            <Helmet>
                <title>{pageTitle ?? breadcrumb}</title>
            </Helmet>
            <main className={classes.main}>
                <TopBar buttons={buttons} breadcrumb={currentPath} />
                <div className={classes.content}>{children}</div>
            </main>
        </>
    );
};

export default BaseLayout;
